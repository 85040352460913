import * as t from 'io-ts';
export const product = t.type({
    name: t.string,
    purpose: t.array(t.string),
    subordinateProducts: t.array(t.type({
        typology: t.union([t.string, t.literal('Istruttoria'), t.literal('Mediazione')]),
    })),
    typology: t.string,
    typologyId: t.string,
});
