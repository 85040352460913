import { useEffect, useState } from 'react';
const VISIBILITY_CHANGE = 'visibilitychange';
const useActiveTab = () => {
    const [isTabActive, setIsTabActive] = useState(null);
    useEffect(() => {
        const handleVisibilityChange = () => {
            const isVisible = document.visibilityState === 'visible';
            setIsTabActive(prev => (prev !== isVisible ? isVisible : prev));
        };
        document.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange);
        return () => {
            document.removeEventListener(VISIBILITY_CHANGE, handleVisibilityChange);
        };
    }, []);
    return isTabActive;
};
export default useActiveTab;
