import { UpdatePaperworkMapper } from './UpdatePaperworkMapper';
export class UpdatePaperworkFieldsMapper extends UpdatePaperworkMapper {
    static map(paperworkDataModel, formData) {
        const fieldsToUpdate = this.sanitizeFlatRecord({
            data_stipula_definitiva: formData.actualLendingDate || null,
            mutuo_importo_richiesto: formData.requestedAmount || null,
            mutuo_importo_di_mediazione: formData.brokerageAmount || null,
        });
        return Object.assign(Object.assign({}, paperworkDataModel), fieldsToUpdate);
    }
}
