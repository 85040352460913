import * as t from 'io-ts';
import { isAnInAgreementInstitute } from './InstituteModel';
export const productModel = t.type({
    name: t.string,
    purpose: t.array(t.string),
    subordinateProducts: t.array(t.type({
        typology: t.union([t.string, t.literal('Istruttoria'), t.literal('Mediazione')]),
    })),
    typology: t.string,
    typologyId: t.string,
});
export const hasProductField = (products, instituteName) => typeof instituteName === 'string' && isAnInAgreementInstitute(instituteName) && products.length > 0;
