import { format } from 'date-fns';
import { numericFormatter } from 'react-number-format';
import { currencyFormatterRules } from '../utils/currencyFormatterRules';
const DATE_FORMAT = 'dd/MM/yyyy';
const formatAmount = (formCurrency) => {
    const EURO = '€';
    if (formCurrency) {
        return `${numericFormatter(formCurrency.toString(), currencyFormatterRules)} ${EURO}`;
    }
    return null;
};
export const standalonePolicyDetailStateModelMapper = (policyDataModel, permissions) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    return [
        { section: [{ title: 'Store di riferimento', rows: { Store: policyDataModel.store || null } }] },
        {
            hidden: !permissions.canViewChannel,
            section: [
                {
                    title: 'Rubinetto',
                    hidden: !permissions.canViewChannel,
                    rows: { Rubinetto: policyDataModel.rubinetto },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Mandato',
                    rows: {
                        'Data firma mandato': policyDataModel.data_firma_mandato
                            ? format(policyDataModel.data_firma_mandato, DATE_FORMAT)
                            : null,
                        'Data scadenza mandato': policyDataModel.data_scadenza_mandato
                            ? format(policyDataModel.data_scadenza_mandato, DATE_FORMAT)
                            : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Finanziamento',
                    rows: {
                        'Data erogazione finanziamento': policyDataModel.data_erogazione_finanziamento
                            ? format(policyDataModel.data_erogazione_finanziamento, DATE_FORMAT)
                            : null,
                        'Mezzo di pagamento finanziamento': (_a = policyDataModel.mezzo_di_pagamento_finanziamento) !== null && _a !== void 0 ? _a : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Polizza assicurativa',
                    rows: {
                        'Tipo di polizza': policyDataModel.polizza_assicurativa_tipo_di_polizza || null,
                        'Compagnia assicurativa': policyDataModel.polizza_assicurativa_compagnia_assicurativa || null,
                        Prodotto: policyDataModel.polizza_assicurativa_prodotto || null,
                        'Tipologia di copertura': policyDataModel.polizza_assicurativa_tipo_di_copertura || null,
                        'Importo della polizza': formatAmount(policyDataModel.polizza_assicurativa_valore_della_polizza),
                        'Tipo di premio': policyDataModel.polizza_assicurativa_tipo_di_premio || null,
                        'Importo di brokeraggio': formatAmount(policyDataModel.polizza_assicurativa_importo_di_brokeraggio),
                        Note: policyDataModel.note || null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: `${policyDataModel.nome} ${policyDataModel.cognome}`,
                    rows: {
                        'Indirizzo di residenza': (_b = policyDataModel.indirizzo_di_residenza) !== null && _b !== void 0 ? _b : null,
                        'Località di residenza': (_c = policyDataModel.localita_di_residenza) !== null && _c !== void 0 ? _c : null,
                        'CAP di residenza': (_d = policyDataModel.cap_di_residenza) !== null && _d !== void 0 ? _d : null,
                        'Comune di nascita': (_e = policyDataModel.comune_di_nascita) !== null && _e !== void 0 ? _e : null,
                        'Provincia di nascita': (_f = policyDataModel.provincia_di_nascita) !== null && _f !== void 0 ? _f : null,
                        'Data di nascita': policyDataModel.data_di_nascita
                            ? format(policyDataModel.data_di_nascita, DATE_FORMAT)
                            : null,
                        Sesso: (_g = policyDataModel.sesso) !== null && _g !== void 0 ? _g : '',
                        'Codice fiscale': policyDataModel.codice_fiscale,
                        'Tipo di documento': (_h = policyDataModel.tipo_di_documento) !== null && _h !== void 0 ? _h : null,
                        Cittadinanza: (_j = policyDataModel.cittadinanza) !== null && _j !== void 0 ? _j : null,
                        'N° di documento': (_k = policyDataModel.n_di_documento) !== null && _k !== void 0 ? _k : null,
                        'Rilasciato da': (_l = policyDataModel.rilasciato_da) !== null && _l !== void 0 ? _l : null,
                        'Luogo di rilascio': (_m = policyDataModel.luogo_di_rilascio) !== null && _m !== void 0 ? _m : null,
                        'Data di rilascio': policyDataModel.data_di_rilascio
                            ? format(policyDataModel.data_di_rilascio, DATE_FORMAT)
                            : null,
                        'Data di scadenza': policyDataModel.data_di_scadenza
                            ? format(policyDataModel.data_di_scadenza, DATE_FORMAT)
                            : null,
                    },
                },
                {
                    title: 'Contatti',
                    rows: {
                        'Telefono cellulare': policyDataModel.telefono_cellulare,
                        'Telefono fisso': (_o = policyDataModel.telefono_fisso) !== null && _o !== void 0 ? _o : null,
                        Email: policyDataModel.email,
                    },
                },
                {
                    title: 'Dati reddituali',
                    rows: {
                        'Stato civile': (_p = policyDataModel.stato_civile) !== null && _p !== void 0 ? _p : null,
                        Professione: (_q = policyDataModel.professione) !== null && _q !== void 0 ? _q : null,
                        'Tipo di azienda': (_r = policyDataModel.tipo_di_azienda) !== null && _r !== void 0 ? _r : null,
                        'P.IVA datore di lavoro': (_s = policyDataModel.partita_iva_datore_di_lavoro) !== null && _s !== void 0 ? _s : null,
                        'Reddito mensile netto': policyDataModel.reddito_mensile_netto
                            ? `${numericFormatter(policyDataModel.reddito_mensile_netto.toString(), currencyFormatterRules)} €`
                            : null,
                        'Numero di mensilità': (_u = (_t = policyDataModel.numero_di_mensilita) === null || _t === void 0 ? void 0 : _t.toString()) !== null && _u !== void 0 ? _u : null,
                        'Anzianità lavorativa': policyDataModel.anzianita_lavorativa
                            ? `${policyDataModel.anzianita_lavorativa} anni`
                            : null,
                        'Componenti nucleo familiare': (_w = (_v = policyDataModel.componenti_nucleo_familiare) === null || _v === void 0 ? void 0 : _v.toString()) !== null && _w !== void 0 ? _w : null,
                    },
                },
                {
                    title: 'Consensi',
                    rows: {
                        "Consenso per l'invio di comunicazioni promozionali e di marketing": (_x = policyDataModel.consenso_invio_comunicazioni_promozionali_e_marketing) !== null && _x !== void 0 ? _x : false,
                        'Consenso per la cessione di dati a terzi': (_y = policyDataModel.consenso_cessione_dati_a_terzi) !== null && _y !== void 0 ? _y : false,
                    },
                },
            ],
        },
    ];
};
