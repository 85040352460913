import { option } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { UpdatePaperworkMapper } from './UpdatePaperworkMapper';
export class UpdateLoanPaperworkMapper extends UpdatePaperworkMapper {
    static getParticipantPrefix(participantIndex) {
        switch (participantIndex) {
            case this.FIRST_APPLICANT_INDEX:
                return '';
            case this.SECOND_APPLICANT_INDEX:
                return 'intestatario_0_';
            default:
                return '';
        }
    }
    static getPolicyPrefix(participantIndex) {
        switch (participantIndex) {
            case this.FIRST_POLICY_INDEX:
                return 'polizza_assicurativa_';
            case this.SECOND_POLICY_INDEX:
                return 'polizza_assicurativa_1_';
            case this.THIRD_POLICY_INDEX:
                return 'polizza_assicurativa_2_';
            case this.FOURTH_POLICY_INDEX:
                return 'polizza_assicurativa_3_';
            case this.FIFTH_POLICY_INDEX:
                return 'polizza_assicurativa_4_';
            default:
                return '';
        }
    }
    static getParticipants(participantIndex, participants) {
        if (!participants) {
            return {};
        }
        const applicant = participants.find(participant => participant.index === participantIndex);
        if (applicant === undefined) {
            return {};
        }
        const prefix = this.getParticipantPrefix(participantIndex);
        return {
            [`${prefix}nome`]: applicant.firstName,
            [`${prefix}cognome`]: applicant.lastName,
            [`${prefix}nome_e_cognome`]: `${applicant.firstName} ${applicant.lastName}`,
            [`${prefix}indirizzo_di_residenza`]: applicant.residence.address,
            [`${prefix}localita_di_residenza`]: applicant.residence.locationInput,
            [`${prefix}comune_di_residenza`]: pipe(UpdateLoanPaperworkMapper.mapCityAndProvinceShortName(applicant.residence.locationInput), option.map(({ city }) => city), option.getOrElse(constant(''))),
            [`${prefix}provincia_di_residenza`]: pipe(UpdateLoanPaperworkMapper.mapCityAndProvinceShortName(applicant.residence.locationInput), option.map(({ provinceShortName }) => provinceShortName), option.getOrElse(constant(''))),
            [`${prefix}cap_di_residenza`]: applicant.residence.postalCode,
            [`${prefix}comune_di_nascita`]: applicant.cityOfBirth,
            [`${prefix}provincia_di_nascita`]: applicant.provinceOfBirth,
            [`${prefix}data_di_nascita`]: applicant.birthday,
            [`${prefix}sesso`]: applicant.gender,
            [`${prefix}codice_fiscale`]: applicant.fiscalCode,
            [`${prefix}tipo_di_documento`]: applicant.identityDocument.type,
            [`${prefix}cittadinanza`]: applicant.citizenship,
            [`${prefix}n_di_documento`]: applicant.identityDocument.code,
            [`${prefix}rilasciato_da`]: applicant.identityDocument.issuedBy,
            [`${prefix}luogo_di_rilascio`]: applicant.identityDocument.placeOfIssue,
            [`${prefix}data_di_rilascio`]: applicant.identityDocument.issueDate,
            [`${prefix}data_di_scadenza`]: applicant.identityDocument.expiryDate,
            [`${prefix}telefono_cellulare`]: applicant.contacts.mobilePhone,
            [`${prefix}telefono_fisso`]: applicant.contacts.landline,
            [`${prefix}email`]: applicant.contacts.email,
            [`${prefix}stato_civile`]: applicant.maritalStatus,
            [`${prefix}professione`]: applicant.job,
            [`${prefix}tipo_di_azienda`]: applicant.companyType,
            [`${prefix}partita_iva_datore_di_lavoro`]: applicant.employerVatNumber,
            [`${prefix}reddito_mensile_netto`]: applicant.netIncome.toString(),
            [`${prefix}numero_di_mensilita`]: applicant.monthlyInstallment,
            [`${prefix}anzianita_lavorativa`]: applicant.lengthOfService,
            [`${prefix}componenti_nucleo_familiare`]: applicant.household,
            [`${prefix}luogo_di_nascita`]: `${applicant.cityOfBirth} (${applicant.provinceOfBirth})`,
            [`${prefix}consenso_invio_comunicazioni_promozionali_e_marketing`]: applicant.marketing,
            [`${prefix}consenso_cessione_dati_a_terzi`]: applicant.thirdParties,
        };
    }
    static getPolicies(index, policies) {
        if (!policies) {
            return {};
        }
        const policy = policies.find(p => p.index === index);
        if (policy === undefined) {
            return {};
        }
        const prefix = this.getPolicyPrefix(index);
        return Object.assign(Object.assign({ [`${prefix}compagnia_assicurativa`]: policy.institute }, (policy.brokerageAmount !== undefined && {
            [`${prefix}importo_di_brokeraggio`]: policy.brokerageAmount.toString(),
        })), { [`${prefix}prodotto`]: policy.product, [`${prefix}tipo_di_polizza`]: policy.type, [`${prefix}tipo_di_premio`]: policy.premiumType, [`${prefix}tipo_di_copertura`]: policy.typeOfCoverage, [`${prefix}valore_della_polizza`]: policy.amount.toString() });
    }
    static getOperationalData(formData) {
        if (!formData) {
            return;
        }
        return {
            store: formData.store,
            data_firma_mandato: formData.warrantStartDate,
            data_scadenza_mandato: formData.warrantEndDate,
            data_erogazione_finanziamento: formData.expectedLendingDate,
            mezzo_di_pagamento_finanziamento: formData.lendingPaymentMethod,
        };
    }
    static getMainProduct(formData) {
        if (!formData) {
            return {};
        }
        return {
            prestito_finalita: formData.purpose,
            prestito_finanziaria: formData.institute,
            prestito_prodotto: formData.product,
            prestito_importo_richiesto: formData.requestedAmount,
            prestito_durata_mesi: formData.duration,
            prestito_tipo_di_cliente: formData.customerType,
            prestito_taeg: formData.tan,
            prestito_spese_istruttoria: formData.processingFee,
            prestito_rata: formData.installment,
            prestito_cpi: formData.cpiList.join(', '),
            prestito_cpi_cofidis: formData.hasCpi,
            prestito_cpi_cofidis_importo_richiesto: formData.policyRequestedAmount,
            importo_richiesto: formData.requestedAmount,
        };
    }
    static sanitizeFlatRecord(r) {
        return Object.fromEntries(Object.entries(r).filter(([_, value]) => value !== '' && value !== null));
    }
    static map(paperworkDataModel, formData) {
        return UpdateLoanPaperworkMapper.sanitizeFlatRecord(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, paperworkDataModel), this.getOperationalData(formData.operationalData)), this.getParticipants(this.FIRST_APPLICANT_INDEX, formData.participants)), this.getParticipants(this.SECOND_APPLICANT_INDEX, formData.participants)), this.getPolicies(this.FIRST_POLICY_INDEX, formData.policies)), this.getPolicies(this.SECOND_POLICY_INDEX, formData.policies)), this.getPolicies(this.THIRD_POLICY_INDEX, formData.policies)), this.getPolicies(this.FOURTH_POLICY_INDEX, formData.policies)), this.getPolicies(this.FIFTH_POLICY_INDEX, formData.policies)), this.getMainProduct(formData.loan)));
    }
}
UpdateLoanPaperworkMapper.FIRST_APPLICANT_INDEX = 0;
UpdateLoanPaperworkMapper.SECOND_APPLICANT_INDEX = 1;
UpdateLoanPaperworkMapper.FIRST_POLICY_INDEX = 2;
UpdateLoanPaperworkMapper.SECOND_POLICY_INDEX = 3;
UpdateLoanPaperworkMapper.THIRD_POLICY_INDEX = 4;
UpdateLoanPaperworkMapper.FOURTH_POLICY_INDEX = 5;
UpdateLoanPaperworkMapper.FIFTH_POLICY_INDEX = 6;
