import { $http } from 'fortepiano';
import { either } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { Notification } from '../../../utils/notification';
import { PayinRegistrationInputCodec } from '../../models/PayinRegistrationInput';
import { getPayinsByRecordId } from '../../web/payin/getPayinsByRecordId';
import { registerPayinProduct } from '../../web/payin/registerPayinProduct';
import { $PayinRegistration } from './PayinRegistrationState';
function* registerPayin(artooId, paperworkId, command) {
    try {
        yield* put($PayinRegistration.PayinRegistrationRequested());
        if (paperworkId !== null) {
            yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
            yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
        }
        else {
            yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
            yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
        }
        const formDataEither = pipe(command.payload.formData, PayinRegistrationInputCodec.decode);
        if (either.isLeft(formDataEither)) {
            throw formDataEither.left;
        }
        const registerPayinEither = yield* call(registerPayinProduct(artooId, formDataEither.right));
        if (either.isLeft(registerPayinEither)) {
            throw registerPayinEither.left;
        }
        yield* put($PayinRegistration.PayinRegistered());
        yield* put(NotificationAction.Push(Notification.info('Componente payin aggiunta correttamente')));
        if (command.payload.onSuccess) {
            yield* call(command.payload.onSuccess);
        }
    }
    catch (e) {
        yield* put($PayinRegistration.PayinNotRegistered());
        const errorMessage = pipe(e, $http.HttpErrorC('BadRequest').decode, either.map(httpError => httpError.response.body), either.chain(t.type({ detail: t.string }).decode), either.map(({ detail }) => detail), either.getOrElse(constant('Si è verificato un errore nella registrazione del payin')));
        yield* put(NotificationAction.Push(Notification.error(errorMessage)));
    }
}
function* fetchPayins(artooId, paperworkId) {
    yield* put($PayinRegistration.PayinsRequested());
    if (paperworkId !== null) {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    else {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    const payinsEither = yield* call(getPayinsByRecordId(artooId));
    if (either.isLeft(payinsEither)) {
        yield* put($PayinRegistration.PayinsNotFetched(new Error('Si è verificato un errore')));
    }
    else {
        yield* put($PayinRegistration.PayinsFetched(payinsEither.right));
    }
}
export function* PayinRegistrationSaga() {
    yield* takeLeading($PayinRegistration.start, function* (startCommand) {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.REGISTER_PAYIN)) {
            yield* put($PayinRegistration.Started());
            const task = yield* all([
                yield* takeLatest($PayinRegistration.fetchPayins, function* () {
                    yield* call(fetchPayins, startCommand.payload.artooId, startCommand.payload.paperworkId);
                }),
                yield* takeLatest($PayinRegistration.registerPayin, function* (registerPayinCommand) {
                    yield* call(registerPayin, startCommand.payload.artooId, startCommand.payload.paperworkId, registerPayinCommand);
                }),
            ]);
            yield* put($PayinRegistration.fetchPayins());
            yield* take($PayinRegistration.stop);
            yield* cancel(task);
            yield* put($PayinRegistration.Stopped());
        }
    });
}
