import * as t from 'io-ts';
import { productTypology } from './ProductTypology';
export const paperworkSummary = t.type({
    total: t.number,
    items: t.array(t.type({
        id: t.string,
        currentStatus: t.type({ code: t.string, label: t.string }),
        productType: productTypology,
        assignedTo: t.type({
            artooId: t.string,
            firstName: t.string,
            lastName: t.string,
        }),
        createdBy: t.type({
            artooId: t.string,
            firstName: t.string,
            lastName: t.string,
        }),
        createdAt: t.string,
        data: t.type({
            nome: t.string,
            cognome: t.string,
            email: t.string,
            codice_fiscale: t.string,
            rubinetto: t.string,
            telefono_cellulare: t.string,
            tipo_di_prodotto_long: t.string,
        }),
    })),
});
