export const convertToNumber = (fieldValue) => {
    if (fieldValue === undefined || fieldValue === null) {
        return '';
    }
    const n = Number(fieldValue);
    if (isNaN(n)) {
        return '';
    }
    return n;
};
