export const productTypologyModelMapper = (productTypology) => {
    switch (productTypology) {
        case 'cqs':
            return 'cqs';
        case 'mortgage':
            return 'mortgage';
        case 'loan':
            return 'loan';
        case 'insurance':
            return 'policy';
    }
};
