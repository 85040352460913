import { array, monoid, number, option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $currency } from '../../../models/Currency/Currency';
import { RecordDataInstituteUtils } from '../../../models/RecordDataInstitute';
export const _billablePayins = (payins) => pipe(payins, readonlyArray.map((payin) => ({
    billablePayinAmount: $currency.toEuros(payin.billablePayinAmount),
    leftoverPayinAmount: $currency.toEuros(payin.leftoverPayinAmount),
    totalCommissionAmount: $currency.toEuros(payin.totalCommissionAmount),
    productType: payin.productType,
})), readonlyArray.toArray);
const getInstitute = (paperworkData) => {
    switch (paperworkData.tipo_di_prodotto_long) {
        case 'Mutuo':
            return paperworkData.mutuo_istituto;
        case 'Prestito':
            return paperworkData.prestito_finanziaria;
        case 'Cessione del quinto':
            return paperworkData.cessione_del_quinto_finanziaria;
        case 'Anticipo TFS':
            return paperworkData.anticipo_tfs_finanziaria;
        case 'Polizza assicurativa':
            return paperworkData.polizza_assicurativa_compagnia_assicurativa;
        default:
            return undefined;
    }
};
const fullName = (record) => pipe(record.data, readonlyRecord.lookup('cognome_e_nome'), option.filter(t.string.is));
const getAuthor = (record) => pipe(record.agenti[0], author => `${author.first_name} ${author.last_name}`);
const formattedTotalCommissionAmount = (payins) => pipe(payins, readonlyArray.map(payin => payin.totalCommissionAmount), monoid.concatAll(number.MonoidSum), $currency.toEuros);
export const recordWithCommissionsFetched = (state, event) => {
    const recordItems = pipe(event.payload.recordWithCommissions, readonlyArray.map(({ record, payins, hasError }) => ({
        fullName: pipe(record, fullName, option.getOrElse(constant(''))),
        institute: RecordDataInstituteUtils.institute(record),
        productType: record.data.tipo_di_prodotto,
        totalCommissionAmount: formattedTotalCommissionAmount(payins),
        isTransmitted: pipe(payins, readonlyArray.every(payin => payin.isTotallyEmitted)),
        recordId: record.id,
        artooId: record.id,
        author: getAuthor(record),
        hasError: hasError,
        payins: _billablePayins(payins),
    })), readonlyArray.toArray, array.partition(item => item.isTransmitted));
    const paperworkItems = pipe(event.payload.paperworkWithCommissions, readonlyArray.map(({ record, payins, hasError }) => ({
        fullName: `${record.data.nome} ${record.data.cognome}`,
        institute: getInstitute(record.data) || '',
        productType: record.data.tipo_di_prodotto || '',
        totalCommissionAmount: formattedTotalCommissionAmount(payins),
        isTransmitted: pipe(payins, readonlyArray.every(payin => payin.isTotallyEmitted)),
        recordId: record.id.slice(0, 8),
        artooId: record.artooId || '',
        author: `${record.createdBy.firstName} ${record.createdBy.lastName}`,
        hasError: hasError,
        payins: _billablePayins(payins),
    })), readonlyArray.toArray, array.partition(item => item.isTransmitted));
    return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: false }), error: Object.assign(Object.assign({}, state.error), { recordList: option.none }), itemsToTransmit: [...paperworkItems.left, ...recordItems.left], transmittedItems: [...paperworkItems.right, ...recordItems.right] });
};
