import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ErrorOutlined from '@mui/icons-material/ErrorOutlined';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { option, readonlyArray } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $number } from '../models/Number/Number';
import { $PayinRegistration, $PayinRegistrationSlice } from '../state/payinRegistration/PayinRegistrationState';
import { DialogTitle } from './DialogTitle';
export const PayinRegistrationDialog = ({ onClose, artooId, paperworkId }) => {
    const [productType, setProductType] = useState('');
    const productTypeWithLeftoverPayinAmountList = useAppSelector($PayinRegistrationSlice.selector.productTypeWithLeftoverPayinAmountList);
    const isCommissionsLoading = useAppSelector($PayinRegistrationSlice.selector.isPayinsLoading);
    const isPayinRegistrationLoading = useAppSelector($PayinRegistrationSlice.selector.isPayinRegistrationLoading);
    const initialValues = useAppSelector($PayinRegistrationSlice.selector.initialValues);
    const error = useAppSelector($PayinRegistrationSlice.selector.error);
    const leftoverPayinAmountOption = useAppSelector($PayinRegistrationSlice.selector.leftoverPayinAmount)(productType);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch($PayinRegistration.start({ artooId, paperworkId }));
        return () => {
            dispatch($PayinRegistration.stop());
        };
    }, [dispatch, artooId, paperworkId]);
    const handleOnSubmit = (formData) => {
        dispatch($PayinRegistration.registerPayin({
            formData,
            onSuccess: onClose,
        }));
    };
    return (_jsxs(Dialog, { open: true, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { title: "Registrazione payin", onClose: onClose }), _jsxs(DialogContent, { children: [isCommissionsLoading && (_jsx(Box, { style: {
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 16,
                        }, children: _jsx(CircularProgress, {}) })), error && !isCommissionsLoading && (_jsxs(Grid, { container: true, item: true, alignItems: "center", wrap: "nowrap", style: { marginBottom: 16 }, children: [_jsx(ErrorOutlined, {}), _jsx(Typography, { display: "inline", style: { marginLeft: 16 }, children: error })] })), !error && !isCommissionsLoading && (_jsx(Formik, { onSubmit: handleOnSubmit, initialValues: initialValues, children: ({ handleChange, values }) => (_jsxs(Form, { children: [_jsxs(Grid, { container: true, alignItems: "flex-end", children: [_jsx(Grid, { item: true, xs: 7, style: { marginRight: 16 }, children: _jsxs(FormControl, { fullWidth: true, variant: "standard", required: true, style: { marginBottom: 16 }, children: [_jsx(InputLabel, { shrink: true, children: "Seleziona una componente provvigionale" }), _jsxs(Select, { variant: "standard", displayEmpty: true, name: "productType", value: values.productType, onChange: e => {
                                                            setProductType(e.target.value);
                                                            handleChange(e);
                                                        }, children: [_jsx(MenuItem, { disabled: true, value: "", children: _jsx("em", { children: "Componenti provvigionali" }) }), pipe(productTypeWithLeftoverPayinAmountList, readonlyArray.mapWithIndex((index, productTypeWithLeftoverPayinAmount) => (_jsx(MenuItem, { value: productTypeWithLeftoverPayinAmount.productType, children: productTypeWithLeftoverPayinAmount.productType }, index))))] })] }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(TextField, { variant: "standard", required: true, style: { marginBottom: 16 }, fullWidth: true, name: "amount", label: "Importo payin", InputProps: {
                                                    startAdornment: _jsx(InputAdornment, { position: "start", children: "\u20AC" }),
                                                }, type: "number", value: $number.roundToSecondDigit(values.amount), onChange: handleChange }) })] }), option.isSome(leftoverPayinAmountOption) && (_jsx(Grid, { container: true, children: _jsxs(Typography, { variant: "body2", children: ["Payin residuo: ", leftoverPayinAmountOption.value] }) })), _jsx(Grid, { container: true, item: true, justifyContent: "center", style: { marginTop: 16 }, children: _jsx(Button, { type: "submit", disabled: $PayinRegistrationSlice.isAnyFieldEmpty(values) ||
                                            isPayinRegistrationLoading, children: "Salva" }) })] })) }))] })] }));
};
