import { either } from 'fp-ts';
import { call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { getPaperwork } from '../../web/getPaperwork';
import { getRecordList } from '../../web/getRecordList';
import { $RecordListWithPayin } from './RecordListWithPayinState';
function* fetchRecordList(command) {
    try {
        yield* put($RecordListWithPayin.RecordListRequested());
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
        const recordListEither = yield* call(getRecordList({
            firstName: command.payload.firstName,
            lastName: command.payload.lastName,
            page: { size: 10 },
        }));
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
        const paperworkListEither = yield* call(getPaperwork({
            filter: {
                customerFirstName: command.payload.firstName,
                customerLastName: command.payload.lastName,
                page: { size: 10 },
            },
        }));
        if (either.isLeft(recordListEither)) {
            throw recordListEither.left;
        }
        if (either.isLeft(paperworkListEither)) {
            throw paperworkListEither.left;
        }
        yield* put($RecordListWithPayin.RecordListFetched({
            bazaarRecord: paperworkListEither.right,
            artooRecord: recordListEither.right,
        }));
    }
    catch (e) {
        yield* put($RecordListWithPayin.RecordListNotFetched(e));
    }
}
export function* RecordListWithPayinSaga() {
    yield* takeLeading($RecordListWithPayin.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_RECORD_LIST_WITH_PAYIN)) {
            yield* put($RecordListWithPayin.Started());
            const task = yield* takeLatest($RecordListWithPayin.fetchRecordList, fetchRecordList);
            yield* take($RecordListWithPayin.stop);
            yield* cancel(task);
            yield* put($RecordListWithPayin.Stopped());
        }
    });
}
