import { either } from 'fp-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { getPaperworkById } from '../../web/record/getPaperworkById';
import { getRecordById } from '../../web/record/getRecordById';
import { $ActiveInvoicing } from './ActiveInvoicingState';
function* fetchRecord(recordId) {
    yield* put($ActiveInvoicing.RecordRequested());
    const isArtooId = !isNaN(Number(recordId));
    if (isArtooId) {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
        const recordEither = yield* call(getRecordById(recordId));
        if (either.isLeft(recordEither)) {
            yield* put($ActiveInvoicing.RecordNotFetched());
            return;
        }
        yield* put($ActiveInvoicing.RecordFetched({ type: 'artoo', record: recordEither.right }));
        return;
    }
    yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
    yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    const paperworkEither = yield* call(getPaperworkById(recordId.toString()));
    if (either.isLeft(paperworkEither)) {
        yield* put($ActiveInvoicing.RecordNotFetched());
        return;
    }
    yield* put($ActiveInvoicing.RecordFetched({ type: 'fmp', paperwork: paperworkEither.right }));
    return;
}
export function* ActiveInvoicingSaga() {
    yield* takeLeading($ActiveInvoicing.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ACTIVE_INVOICING_RECORD_LIST)) {
            yield* put($ActiveInvoicing.Started());
            const task = yield* all([
                yield* takeLatest($ActiveInvoicing.fetchRecord, function* (fetchRecordCommand) {
                    yield* call(fetchRecord, fetchRecordCommand.payload.recordId);
                }),
            ]);
            yield* take($ActiveInvoicing.stop);
            yield* cancel(task);
            yield* put($ActiveInvoicing.Stopped());
        }
    });
}
