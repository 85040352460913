import { all, put, select, take, takeLatest } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../../authorization/useAuthorization';
import { SecurityActions } from '../../../../store/security/SecurityActions';
import { PERMISSION, SecurityState } from '../../../../store/security/SecurityState';
import { InvoiceStatusCodeUtils } from '../../../models/InvoiceStatusCode/InvoiceStatusCode';
import { $InvoiceApproval } from '../../invoiceApproval/InvoiceApprovalState';
import { $InvoiceCreation } from '../../invoiceCreation/InvoiceCreationState';
import { $InvoiceDeletion } from '../../invoiceDeletion/InvoiceDeletionState';
import { $InvoiceEdit } from '../../invoiceEdit/InvoiceEditState';
import { $InvoiceList } from '../../invoiceList/InvoiceListState';
import { $InvoiceTransmission } from '../../invoiceTransmission/InvoiceTransmissionState';
import { $PassiveInvoiceBulkEditActions } from '../../passiveInvoiceBulkEditActions/PassiveInvoiceBulkEditActionsState';
import { $InvoiceToTransmitTab } from './InvoiceToTransmitTabState';
const filter = {
    statusCode: [InvoiceStatusCodeUtils.READY, InvoiceStatusCodeUtils.DRAFT],
    sort: '-_timestamps.createdAt',
};
function* endSagas() {
    yield* put($InvoiceList.endInvoiceList());
    yield* put($InvoiceTransmission.endInvoiceTransmission());
    yield* put($InvoiceDeletion.endInvoiceDeletionCommand());
}
function* invoiceTransmitTab() {
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
    yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ASSIGNEE_TABLE_ROW)) {
        yield* put($InvoiceList.startInvoiceList({
            filter,
            withEnasarcoOverview: true,
            withAssignee: true,
        }));
        yield* take($InvoiceList.InvoiceTableFetched);
        yield* put($InvoiceDeletion.startInvoiceDeletionCommand());
        yield* put($InvoiceTransmission.startInvoiceTransmission());
    }
    else {
        yield* put($InvoiceList.startInvoiceList({ filter, withAssignee: false }));
    }
}
export function* InvoiceToTransmitTabSaga() {
    yield* all([
        yield* takeLatest([
            $InvoiceTransmission.InvoiceTransmitted.type,
            $InvoiceDeletion.InvoiceDeleted.type,
            $InvoiceCreation.InvoiceCreated.type,
            $InvoiceEdit.InvoiceUpdated.type,
            $PassiveInvoiceBulkEditActions.InvoiceListUpdated.type,
            $PassiveInvoiceBulkEditActions.InvoiceListNotUpdated.type,
        ], function* () {
            yield* put($InvoiceList.fetchInvoiceTable());
        }),
        yield* takeLatest($InvoiceApproval.InvoiceApprovalToggled.type, function* () {
            yield* put($InvoiceList.fetchInvoiceTable({ withLoader: false }));
        }),
        yield* takeLatest($InvoiceToTransmitTab.endInvoiceToTransmitTab.type, endSagas),
        yield* takeLatest($InvoiceToTransmitTab.startInvoiceToTransmitTab.type, invoiceTransmitTab),
    ]);
}
