import { either } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { Notification } from '../../../utils/notification';
import { PayinCodec } from '../../models/Payin';
import { editCommissions } from '../../web/editCommissions';
import { getCommissionsTable } from '../../web/getCommissionsTable';
import { getPayinsByRecordId } from '../../web/payin/getPayinsByRecordId';
import { CommissionsTableCodec } from '../commissionsForm/CommissionsTable';
import { $RecordCommissions, $RecordCommissionsSlice } from './RecordCommissionsState';
function* tryEditCommissions(artooId, paperworkId, editCommissionsCommand) {
    yield* put($RecordCommissions.CommissionsEditRequested());
    if (paperworkId !== null) {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    else {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    const commissionsEditedEither = yield* call(editCommissions(artooId, editCommissionsCommand.payload.commissions));
    if (either.isRight(commissionsEditedEither)) {
        const result = yield* all([call(getCommissionsTable(artooId)), call(getPayinsByRecordId(artooId))]);
        const [commissionsEither, payinsEither] = result;
        if (either.isRight(commissionsEither) && either.isRight(payinsEither)) {
            yield* put($RecordCommissions.CommissionsEdited({
                commissions: commissionsEither.right,
                payins: payinsEither.right,
            }));
        }
        else {
            const error = $RecordCommissionsSlice.errorMessage({
                payins: either.isLeft(payinsEither) ? payinsEither.left : undefined,
                commissions: either.isLeft(commissionsEither) ? commissionsEither.left : undefined,
            });
            if (error === undefined) {
                yield* put(NotificationAction.Push(Notification.error($RecordCommissionsSlice.GENERIC_COMMISSIONS_ERROR_MESSAGE)));
            }
            yield* put($RecordCommissions.CommissionsEdited({
                payins: either.isRight(payinsEither) ? payinsEither.right : [],
                commissions: either.isRight(commissionsEither) ? commissionsEither.right : [],
                error: error,
            }));
        }
        yield* put(NotificationAction.Push(Notification.info('Provvigioni modificate correttamente')));
        if (editCommissionsCommand.payload.onSuccess) {
            yield* call(editCommissionsCommand.payload.onSuccess);
        }
    }
    else {
        const error = $RecordCommissionsSlice.errorMessage({
            commissions: commissionsEditedEither.left,
        });
        yield* put($RecordCommissions.CommissionsNotEdited(error));
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore durante il salvataggio delle provvigioni')));
    }
}
function* fetchCommissions(artooId, paperworkId) {
    yield* put($RecordCommissions.CommissionsRequested());
    if (paperworkId) {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    else {
        yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
        yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    }
    const result = yield* all([call(getCommissionsTable(artooId)), call(getPayinsByRecordId(artooId))]);
    const commissionsEither = result[0];
    const payinsEither = result[1];
    if (either.isLeft(commissionsEither) && either.isLeft(payinsEither)) {
        const error = $RecordCommissionsSlice.errorMessage({
            commissions: commissionsEither.left,
            payins: payinsEither.left,
        });
        if (error === undefined) {
            yield* put(NotificationAction.Push(Notification.error($RecordCommissionsSlice.GENERIC_COMMISSIONS_ERROR_MESSAGE)));
        }
        yield* put($RecordCommissions.CommissionsNotFetched(error));
    }
    else {
        const commissions = pipe(commissionsEither, either.chainW(CommissionsTableCodec.decode), either.getOrElseW(constant([])));
        const payins = pipe(payinsEither, either.chainW(t.readonlyArray(PayinCodec).decode), either.getOrElseW(constant([])));
        const error = $RecordCommissionsSlice.errorMessage({
            commissions: either.isLeft(commissionsEither) ? commissionsEither.left : undefined,
            payins: either.isLeft(payinsEither) ? payinsEither.left : undefined,
        });
        if (either.isLeft(commissionsEither) || either.isLeft(payinsEither)) {
            if (error === undefined) {
                yield* put(NotificationAction.Push(Notification.error($RecordCommissionsSlice.GENERIC_COMMISSIONS_ERROR_MESSAGE)));
            }
        }
        yield* put($RecordCommissions.CommissionsFetched({
            payins,
            commissions,
            error,
        }));
    }
}
export function* RecordCommissionsSaga() {
    yield* takeLeading($RecordCommissions.start, function* (startCommand) {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_RECORD_COMMISSIONS)) {
            yield* put($RecordCommissions.Started());
            const task = [
                yield* takeLatest($RecordCommissions.fetchCommissions, () => fetchCommissions(startCommand.payload.artooId, startCommand.payload.paperworkId)),
                yield* takeLatest($RecordCommissions.editCommissions, editCommissionsCommand => {
                    if (isUserAuthorized(userPermissionList)(PERMISSION.EDIT_COMMISSIONS)) {
                        return tryEditCommissions(startCommand.payload.artooId, startCommand.payload.paperworkId, editCommissionsCommand);
                    }
                }),
            ];
            yield* put($RecordCommissions.fetchCommissions());
            yield* take($RecordCommissions.stop);
            yield* cancel(task);
            yield* put($RecordCommissions.Stopped());
        }
    });
}
