import * as t from 'io-ts';
export const PaperworkCodec = t.type({
    id: t.string,
    currentStatus: t.type({ code: t.string, label: t.string }),
    productType: t.string,
    assignedTo: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdBy: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdAt: t.string,
    data: t.intersection([
        t.record(t.string, t.unknown),
        t.type({
            nome: t.string,
            cognome: t.string,
            codice_fiscale: t.string,
            indirizzo_di_residenza: t.string,
            cap_di_residenza: t.string,
            comune_di_residenza: t.string,
            provincia_di_residenza: t.string,
            tipo_di_prodotto: t.string,
        }),
        t.partial({
            mutuo_importo_di_mediazione: t.number,
            mutuo_istituto: t.string,
            prestito_finanziaria: t.string,
            cessione_del_quinto_finanziaria: t.string,
            anticipo_tfs_finanziaria: t.string,
            polizza_assicurativa_compagnia_assicurativa: t.string,
        }),
    ]),
}, 'Paperwork');
