import { option, string } from 'fp-ts';
import * as t from 'io-ts';
import { readonlyArray } from '../utils/ReadonlyArray';
import { type } from '../utils/Type';
export const RoleC = type.alias('Role', t.string);
const ROLE_NEXUS_ADMIN = 'ROLE_NEXUS_ADMIN';
const ROLE_NEXUS_AGENTE_COLLABORATORE = 'ROLE_NEXUS_AGENTE_COLLABORATORE';
const ROLE_NEXUS_AGENTE_HEAD_AGENCY = 'ROLE_NEXUS_AGENTE_HEAD_AGENCY';
const ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL = 'ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL';
const ROLE_NEXUS_BERGAMO_AMMINISTRATORE = 'ROLE_NEXUS_BERGAMO_AMMINISTRATORE';
const ROLE_NEXUS_BERGAMO_OPERATORE = 'ROLE_NEXUS_BERGAMO_OPERATORE';
const ROLE_NEXUS_BERGAMO_OBSERVER = 'ROLE_NEXUS_BERGAMO_OBSERVER';
const ROLE_NEXUS_TIRANA_SUPERVISORE = 'ROLE_NEXUS_TIRANA_SUPERVISORE';
const ROLE_NEXUS_TIRANA_OPERATORE = 'ROLE_NEXUS_TIRANA_OPERATORE';
const ROLE_NEXUS_BILLABLE = 'ROLE_NEXUS_BILLABLE';
const ROLE_AGENTE = 'ROLE_AGENTE';
const ROLE_NEXUS_COMMISSION_PROFILE = 'ROLE_NEXUS_COMMISSION_PROFILE';
const ROLE_NEXUS_QUOTING_TOOL__MORTGAGE = 'ROLE_NEXUS_QUOTING_TOOL__MORTGAGE';
const ROLE_NEXUS_NEW_WORKFLOW = 'ROLE_NEXUS_NEW_WORKFLOW';
const _Eq = string.Eq;
const hasRole = (role, ...roles) => (user) => readonlyArray.anyElem($role.Eq)(role, ...roles)(user.roles);
const isAdmin = option.exists(hasRole(ROLE_NEXUS_ADMIN));
const isAgent = option.exists(hasRole(ROLE_NEXUS_AGENTE_COLLABORATORE, ROLE_NEXUS_AGENTE_HEAD_AGENCY, ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL));
export const $role = {
    Eq: _Eq,
    isAdmin,
    isAgent,
    hasRole,
    ROLE_NEXUS_ADMIN,
    ROLE_NEXUS_AGENTE_COLLABORATORE,
    ROLE_NEXUS_AGENTE_HEAD_AGENCY,
    ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL,
    ROLE_NEXUS_BERGAMO_AMMINISTRATORE,
    ROLE_NEXUS_BERGAMO_OPERATORE,
    ROLE_NEXUS_BERGAMO_OBSERVER,
    ROLE_NEXUS_TIRANA_SUPERVISORE,
    ROLE_NEXUS_TIRANA_OPERATORE,
    ROLE_AGENTE,
    ROLE_NEXUS_COMMISSION_PROFILE,
    ROLE_NEXUS_QUOTING_TOOL__MORTGAGE,
    ROLE_NEXUS_BILLABLE,
    ROLE_NEXUS_NEW_WORKFLOW,
};
