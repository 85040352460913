import { createSlice } from '@reduxjs/toolkit';
import { array, option, string } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { channelStateMapper } from './mappers/channelStateModelMapper';
import { commissionsTableModelMapper } from './mappers/commissionsTableModelMapper';
import { commissionsTotalStateMapper } from './mappers/commissionsTotalStateMapper';
import { cqsFormStateModelMapper } from './mappers/cqsFormStateModelMapper';
import { detailStateModelMapper } from './mappers/detailStateModelMapper';
import { documentSectionStateModelMapper } from './mappers/documentSectionStateMapper';
import { filesStateModelMapper } from './mappers/filesStateModelMapper';
import { loanFormStateModelMapper } from './mappers/loanFormStateModelMapper';
import { mortgageFormStateModelMapper } from './mappers/mortgageFormStateModelMapper';
import { paperworkDetailStateMapper } from './mappers/paperworkDetailMapper';
import { standalonePolicyFormStateModelMapper } from './mappers/standalonePolicyFormStateModelMapper';
import { uploadedDocumentsMapper } from './mappers/uploadedDocumentsMapper';
import { policyProductsFetched } from './reducers/policyProductsFetched';
import { policyTypeAndProductsFetched } from './reducers/policyTypeAndProductsFetched';
import { setDraft } from './reducers/setDraft';
import { replaceOrAddFile } from './utils/replaceOrAddFile';
export const ALLOWED_LOAN_APPLICANTS = 2;
export const ALLOWED_LOAN_POLICIES = 5;
export const ALLOWED_MORTGAGE_APPLICANTS = 4;
export const ALLOWED_MORTGAGE_GUARANTORS = 2;
export const ALLOWED_MORTGAGE_MORTGAGERS = 2;
export const ALLOWED_POLICIES = 5;
export const FIRST_APPLICANT_INDEX = 0;
export const SECOND_APPLICANT_INDEX = 1;
export const THIRD_APPLICANT_INDEX = 2;
export const FOURTH_APPLICANT_INDEX = 3;
export const FIRST_GUARANTOR_INDEX = 4;
export const SECOND_GUARANTOR_INDEX = 5;
export const FIRST_MORTGAGER_INDEX = 6;
export const SECOND_MORTGAGER_INDEX = 7;
export const FIRST_POLICY_INDEX = 8;
export const SECOND_POLICY_INDEX = 9;
export const THIRD_POLICY_INDEX = 10;
export const FOURTH_POLICY_INDEX = 11;
export const FIFTH_POLICY_INDEX = 12;
export const STANDALONE_POLICY_FORM = {
    type: { label: 'Tipo di polizza', name: 'policy.type' },
    institute: { label: 'Compagnia assicurativa', name: 'policy.institute' },
    product: { label: 'Prodotto', name: 'policy.product' },
    typeOfCoverage: { label: 'Tipo di copertura', name: 'policy.typeOfCoverage' },
    amount: { label: 'Importo polizza', name: 'policy.amount' },
    brokerageAmount: { label: 'Importo di brokeraggio', name: 'policy.brokerageAmount' },
    premiumType: { label: 'Tipo di premio', name: 'policy.premiumType' },
    channel: { label: 'Rubinetto', name: 'operationalData.channelInput' },
    store: { label: 'Store', name: 'operationalData.store' },
    expectedLendingDate: { label: 'Data erogazione finanziamento', name: 'operationalData.expectedLendingDate' },
    lendingPaymentMethod: { label: 'Mezzo di pagamento finanziamento', name: 'operationalData.lendingPaymentMethod' },
    warrantStartDate: { name: 'operationalData.warrantStartDate', label: 'Data firma mandato' },
    warrantEndLabel: { name: 'operationalData.warrantEndLabel', label: 'Data scadenza mandato' },
    notes: { name: 'operationalData.notes', label: 'Note' },
    firstName: { name: 'participant.firstName', label: 'Nome' },
    lastName: { name: 'participant.lastName', label: 'Cognome' },
    residenceAddress: { name: 'participant.residence.address', label: 'Indirizzo di residenza' },
    residenceLocation: { name: 'participant.residence.location', label: 'Località di residenza' },
    residenceLocationInput: { name: 'participant.residence.locationInput', label: 'Località di residenza' },
    residencePostalCode: { name: 'participant.residence.postalCode', label: 'CAP di residenza' },
    cityOfBirth: { name: 'participant.cityOfBirth', label: 'Comune di nascita' },
    provinceOfBirth: { name: 'participant.provinceOfBirth', label: 'Provincia di nascita' },
    birthday: { name: 'participant.birthday', label: 'Data di nascita' },
    gender: { name: 'participant.gender', label: 'Sesso' },
    fiscalCode: { name: 'participant.fiscalCode', label: 'Codice fiscale' },
    identityDocumentType: { name: 'participant.identityDocument.type', label: 'Tipo di documento' },
    citizenship: { name: 'participant.citizenship', label: 'Cittadinanza' },
    identityDocumentCode: { name: 'participant.identityDocument.code', label: 'N° di documento' },
    identityDocumentIssuedBy: { name: 'participant.identityDocument.issuedBy', label: 'Rilasciato da' },
    identityDocumentPlaceOfIssue: { name: 'participant.identityDocument.placeOfIssue', label: 'Luogo di rilascio' },
    identityDocumentIssueDate: { name: 'participant.identityDocument.issueDate', label: 'Data di rilascio' },
    identityDocumentExpiryDate: { name: 'participant.identityDocument.expiryDate', label: 'Data di scadenza' },
    contactsMobilePhone: { name: 'participant.contacts.mobilePhone', label: 'Telefono cellulare' },
    contactsLandline: { name: 'participant.contacts.landline', label: 'Telefono fisso' },
    contactsEmail: { name: 'participant.contacts.email', label: 'Email' },
    maritalStatus: { name: 'participant.maritalStatus', label: 'Stato civile' },
    job: { name: 'participant.job', label: 'Professione' },
    companyType: { name: 'participant.companyType', label: 'Tipo di azienda' },
    netIncome: { name: 'participant.netIncome', label: 'Reddito mensile netto' },
    monthlyInstallment: { name: 'participant.monthlyInstallment', label: 'Numero di mensilità' },
    lengthOfService: { name: 'participant.lengthOfService', label: 'Anzianità lavorativa' },
    household: { name: 'participant.household', label: 'Componenti nucleo familiare' },
    employerVatNumber: { name: 'participant.employerVatNumber', label: 'P.IVA datore di lavoro' },
    marketing: {
        name: 'participant.marketing',
        label: "Consenso per l'invio di comunicazioni promozionali e di marketing",
    },
    thirdParties: { name: 'participant.thirdParties', label: 'Consenso per la cessione di dati a terzi' },
};
export const LOAN_FORM = {
    purpose: { label: 'Finalità', name: 'purpose' },
    institute: { label: 'Finanziaria', name: 'institute' },
    product: { label: 'Prodotto', name: 'product' },
    requestedAmount: { label: 'Importo richiesto', name: 'requestedAmount' },
    duration: { label: 'Durata', name: 'duration' },
    customerType: { label: 'Tipo di cliente', name: 'customerType' },
    tan: { label: 'TAN', name: 'tan' },
    processingFee: { label: 'Spese istruttoria', name: 'processingFee' },
    installment: { label: 'Rata', name: 'installment' },
    cpiList: { label: 'CPI', name: 'cpiList' },
    hasCpi: { label: 'CPI', name: 'hasCpi' },
    policyRequestedAmount: { label: 'Importo polizza', name: 'policyRequestedAmount' },
    notes: { label: 'Note', name: 'notes' },
};
export const MORTGAGE_FORM = {
    type: { label: 'Tipologia di mutuo', name: 'type' },
    mortgageManagement: { label: 'Gestione', name: 'mortgageManagement' },
    institute: { label: 'Istituto', name: 'institute' },
    purpose: { label: 'Finalità', name: 'purpose' },
    product: { label: 'Prodotto', name: 'product' },
    productValue: { label: 'Valore immobile', name: 'propertyValue' },
    requestedAmount: { label: 'Importo richiesto', name: 'requestedAmount' },
    outstandingPrincipalAmount: { label: 'Capitale residuo', name: 'outstandingPrincipalAmount' },
    processingFee: { label: 'Importo istruttoria', name: 'processingFee' },
    renovationAmount: { label: 'Importo ristrutturazione', name: 'renovationAmount' },
    brokerageAmount: { label: 'Importo di mediazione', name: 'brokerageAmount' },
    brokeragePercentage: { label: 'Percentuale di mediazione', name: 'brokeragePercentage' },
    buildingType: { label: 'Tipo di immobile', name: 'buildingType' },
    energyClass: { label: 'Classe energetica immobile', name: 'energyClass' },
    searchStatus: { label: 'Stato ricerca immobile', name: 'searchStatus' },
    LTV: { label: 'LTV', name: 'ltv' },
    location: { label: 'Località immobile', name: 'location' },
    duration: { label: 'Durata', name: 'duration' },
    rateType: { label: 'Tipo di tasso', name: 'rateType' },
    spread: { label: 'Spread', name: 'spread' },
    suggestedBrokerageAmount: { label: '', name: 'suggestedBrokerageAmount' },
};
export const MORTGAGE_PARTICIPANT = {
    firstName: { name: 'firstName', label: 'Nome' },
    lastName: { name: 'lastName', label: 'Cognome' },
    residenceAddress: { name: 'residence.address', label: 'Indirizzo di residenza' },
    residenceLocation: { name: 'residence.location', label: 'Località di residenza' },
    residencePostalCode: { name: 'residence.postalCode', label: 'CAP di residenza' },
    cityOfBirth: { name: 'cityOfBirth', label: 'Comune di nascita' },
    provinceOfBirth: { name: 'provinceOfBirth', label: 'Provincia di nascita' },
    birthday: { name: 'birthday', label: 'Data di nascita' },
    gender: { name: 'gender', label: 'Sesso' },
    fiscalCode: { name: 'fiscalCode', label: 'Codice fiscale' },
    identityDocumentType: { name: 'identityDocument.type', label: 'Tipo di documento' },
    citizenship: { name: 'citizenship', label: 'Cittadinanza' },
    identityDocumentCode: { name: 'identityDocument.code', label: 'N° di documento' },
    identityDocumentIssuedBy: { name: 'identityDocument.issuedBy', label: 'Rilasciato da' },
    identityDocumentPlaceOfIssue: { name: 'identityDocument.placeOfIssue', label: 'Luogo di rilascio' },
    identityDocumentIssueDate: { name: 'identityDocument.issueDate', label: 'Data di rilascio' },
    identityDocumentExpiryDate: { name: 'identityDocument.expiryDate', label: 'Data di scadenza' },
    contactsMobilePhone: { name: 'contacts.mobilePhone', label: 'Telefono cellulare' },
    contactsEmail: { name: 'contacts.email', label: 'Email' },
    maritalStatus: { name: 'maritalStatus', label: 'Stato civile' },
    job: { name: 'job', label: 'Professione' },
    companyType: { name: 'companyType', label: 'Tipo di azienda' },
    netIncome: { name: 'netIncome', label: 'Reddito mensile netto' },
    monthlyInstallment: { name: 'monthlyInstallment', label: 'Numero di mensilità' },
    lengthOfService: { name: 'lengthOfService', label: 'Anzianità lavorativa' },
    household: { name: 'household', label: 'Componenti nucleo familiare' },
    employerVatNumber: { name: 'employerVatNumber', label: 'P.IVA datore di lavoro' },
};
export const LOAN_PARTICIPANT = {
    firstName: { name: 'firstName', label: 'Nome' },
    lastName: { name: 'lastName', label: 'Cognome' },
    residenceAddress: { name: 'residence.address', label: 'Indirizzo di residenza' },
    residenceLocation: { name: 'residence.location', label: 'Località di residenza' },
    residencePostalCode: { name: 'residence.postalCode', label: 'CAP di residenza' },
    cityOfBirth: { name: 'cityOfBirth', label: 'Comune di nascita' },
    provinceOfBirth: { name: 'provinceOfBirth', label: 'Provincia di nascita' },
    birthday: { name: 'birthday', label: 'Data di nascita' },
    gender: { name: 'gender', label: 'Sesso' },
    fiscalCode: { name: 'fiscalCode', label: 'Codice fiscale' },
    identityDocumentType: { name: 'identityDocument.type', label: 'Tipo di documento' },
    citizenship: { name: 'citizenship', label: 'Cittadinanza' },
    identityDocumentCode: { name: 'identityDocument.code', label: 'N° di documento' },
    identityDocumentIssuedBy: { name: 'identityDocument.issuedBy', label: 'Rilasciato da' },
    identityDocumentPlaceOfIssue: { name: 'identityDocument.placeOfIssue', label: 'Luogo di rilascio' },
    identityDocumentIssueDate: { name: 'identityDocument.issueDate', label: 'Data di rilascio' },
    identityDocumentExpiryDate: { name: 'identityDocument.expiryDate', label: 'Data di scadenza' },
    contactsMobilePhone: { name: 'contacts.mobilePhone', label: 'Telefono cellulare' },
    contactsEmail: { name: 'contacts.email', label: 'Email' },
    maritalStatus: { name: 'maritalStatus', label: 'Stato civile' },
    job: { name: 'job', label: 'Professione' },
    companyType: { name: 'companyType', label: 'Tipo di azienda' },
    netIncome: { name: 'netIncome', label: 'Reddito mensile netto' },
    monthlyInstallment: { name: 'monthlyInstallment', label: 'Numero di mensilità' },
    lengthOfService: { name: 'lengthOfService', label: 'Anzianità lavorativa' },
    household: { name: 'household', label: 'Componenti nucleo familiare' },
    employerVatNumber: { name: 'employerVatNumber', label: 'P.IVA datore di lavoro' },
};
export const MORTGAGE_POLICY = {
    type: { name: 'type', label: 'Tipo di polizza' },
    institute: { name: 'institute', label: 'Compagnia assicurativa' },
    product: { name: 'product', label: 'Prodotto' },
    amount: { name: 'amount', label: 'Importo della polizza' },
    typeOfCoverage: { name: 'typeOfCoverage', label: 'Tipologia di copertura' },
    premiumType: { name: 'premiumType', label: 'Tipo di premio' },
    brokerageAmount: { name: 'brokerageAmount', label: 'Importo di brokeraggio' },
};
export const LOAN_POLICY = {
    type: { name: 'type', label: 'Tipo di polizza' },
    institute: { name: 'institute', label: 'Compagnia assicurativa' },
    product: { name: 'product', label: 'Prodotto' },
    amount: { name: 'amount', label: 'Importo della polizza' },
    typeOfCoverage: { name: 'typeOfCoverage', label: 'Tipologia di copertura' },
    premiumType: { name: 'premiumType', label: 'Tipo di premio' },
    brokerageAmount: { name: 'brokerageAmount', label: 'Importo di brokeraggio' },
};
export const MORTGAGE_LENDING_SECTION = {
    expectedLendingDate: { name: 'expectedLendingDate', label: 'Data di erogazione presunta' },
};
export const PARTICIPANTS_DETAIL_MODEL = {
    PARTICIPANTS: {
        FIRST_APPLICANT: FIRST_APPLICANT_INDEX,
        SECOND_APPLICANT: SECOND_APPLICANT_INDEX,
        THIRD_APPLICANT: THIRD_APPLICANT_INDEX,
        FOURTH_APPLICANT: FOURTH_APPLICANT_INDEX,
        FIRST_GUARANTOR: FIRST_GUARANTOR_INDEX,
        SECOND_GUARANTOR: SECOND_GUARANTOR_INDEX,
        FIRST_MORTGAGER: FIRST_MORTGAGER_INDEX,
        SECOND_MORTGAGER: SECOND_MORTGAGER_INDEX,
    },
    POLICIES: {
        FIRST_POLICY: FIRST_POLICY_INDEX,
        SECOND_POLICY: SECOND_POLICY_INDEX,
        THIRD_POLICY: THIRD_POLICY_INDEX,
        FOURTH_POLICY: FOURTH_POLICY_INDEX,
        FIFTH_POLICY: FIFTH_POLICY_INDEX,
    },
};
export const DETAIL_STATE_MODEL = {
    OPERATIONAL_DATA: 'operationalData',
    MORTGAGE: 'mortgage',
    PARTICIPANTS: PARTICIPANTS_DETAIL_MODEL.PARTICIPANTS,
    POLICIES: PARTICIPANTS_DETAIL_MODEL.POLICIES,
};
export const LOAN_DETAIL_STATE_MODEL = {
    LOAN: 'loan',
    OPERATIONAL_DATA: 'operationalData',
    PARTICIPANTS: {
        FIRST_APPLICANT: 0,
        SECOND_APPLICANT: 1,
    },
    POLICIES: {
        FIRST_POLICY: 2,
        SECOND_POLICY: 3,
        THIRD_POLICY: 4,
        FOURTH_POLICY: 5,
        FIFTH_POLICY: 6,
    },
};
export const initialPolicyState = (index) => {
    return {
        typeOfCoverage: '',
        amount: '',
        brokerageAmount: '',
        index: index,
        institute: '',
        premiumType: '',
        product: '',
        type: '',
    };
};
export const initialMortgageParticipantState = (type, index) => ({
    type: type,
    index: index,
    canBeDeleted: false,
    firstName: '',
    lastName: '',
    residence: {
        address: '',
        locationInput: '',
        location: {
            name: '',
            province: {
                shortName: '',
            },
        },
        postalCode: '',
    },
    cityOfBirth: '',
    provinceOfBirth: '',
    birthday: '',
    gender: '',
    fiscalCode: '',
    identityDocument: {
        type: '',
        code: '',
        issuedBy: '',
        placeOfIssue: '',
        issueDate: '',
        expiryDate: '',
    },
    contacts: {
        mobilePhone: '',
        landline: '',
        email: '',
    },
    citizenship: '',
    maritalStatus: '',
    job: '',
    companyType: '',
    employerVatNumber: '',
    netIncome: '',
    monthlyInstallment: '',
    lengthOfService: '',
    household: '',
    marketing: false,
    thirdParties: false,
});
export const initialLoanParticipantState = (index) => ({
    type: 'applicant',
    index: index,
    canBeDeleted: false,
    firstName: '',
    lastName: '',
    residence: {
        address: '',
        locationInput: '',
        location: {
            name: '',
            province: {
                shortName: '',
            },
        },
        postalCode: '',
    },
    cityOfBirth: '',
    provinceOfBirth: '',
    birthday: '',
    gender: '',
    fiscalCode: '',
    identityDocument: {
        type: '',
        code: '',
        issuedBy: '',
        placeOfIssue: '',
        issueDate: '',
        expiryDate: '',
    },
    contacts: {
        mobilePhone: '',
        landline: '',
        email: '',
    },
    citizenship: '',
    maritalStatus: '',
    job: '',
    companyType: '',
    employerVatNumber: '',
    netIncome: '',
    monthlyInstallment: '',
    lengthOfService: '',
    household: '',
    marketing: false,
    thirdParties: false,
});
const initialState = {
    initialValues: { cqs: null, mortgage: null, loan: null, policy: null },
    loading: {
        store: false,
        channel: false,
        institute: false,
        product: false,
        participantPlaceOfResidence: false,
        paperwork: false,
        commissions: false,
        processPaperwork: false,
        signers: false,
        downloadFile: false,
        purpose: false,
        policy: false,
    },
    errors: { signers: false },
    fieldErrors: {
        store: undefined,
        channel: undefined,
        institute: undefined,
        product: undefined,
        participantPlaceOfResidence: undefined,
        purpose: undefined,
        policyProduct: undefined,
        policyInstitute: undefined,
    },
    stores: [],
    channels: [],
    institutes: [],
    products: [],
    purposes: [],
    participantPlaceOfResidence: [],
    commissions: { table: { body: [], headers: [] }, total: null, error: null },
    paperworkDetail: null,
    detail: null,
    files: {},
    signers: [],
    documentSection: {
        documentTypes: [],
        mandatoryDocuments: 0,
        uploadedDocuments: 0,
        documentsToBeSigned: 0,
        signedDocuments: 0,
    },
    draft: {},
    policy: { institutes: [], products: [], types: [] },
    permissions: { viewPaperwork: null },
};
const GENERIC_ERROR = 'Si è verificato un errore';
export const _removeFile = (fileToRemove, files) => pipe(files, array.findIndex(file => string.Eq.equals(file.name, fileToRemove.name)), option.match(constant(files), index => pipe(files, array.deleteAt(index), option.getOrElse(constant(files)))));
export const _canBeDeleted = (record) => {
    return !Object.values(record).some(isDraft => isDraft);
};
export const paperworkProcessSlice = createSlice({
    name: 'PaperworkProcessSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        fetchChannels: identity,
        fetchLocation: (state, _) => state,
        fetchStores: (state, _) => state,
        fetchInstitutes: (state, _) => state,
        fetchProducts: (state, _) => state,
        fetchPurposes: (state, _) => state,
        updatePaperwork: (state, _) => state,
        UpdatePaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkUpdated: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(null, event.payload.paperwork, event.payload.permissions, state.draft) })),
        PaperworkNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        updatePaperworkAndDocumentation: (state, _) => state,
        fillForm: (state, _) => state,
        fetchCommissions: identity,
        processPaperwork: (state, _) => state,
        signPaperwork: (state, _) => state,
        fetchSigners: identity,
        downloadFile: (state, _) => state,
        DownloadFileRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { downloadFile: true }) })),
        FileDownloaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { downloadFile: false }) })),
        FileNotDownloaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { downloadFile: false }) })),
        SignersRequested: state => (Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { signers: false }), loading: Object.assign(Object.assign({}, state.loading), { signers: true }) })),
        SignersFetched: (state, event) => (Object.assign(Object.assign({}, state), { signers: event.payload.signers, loading: Object.assign(Object.assign({}, state.loading), { signers: false }), errors: Object.assign(Object.assign({}, state.errors), { signers: false }) })),
        SignersNotFetched: state => (Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { signers: true }), loading: Object.assign(Object.assign({}, state.loading), { signers: false }) })),
        clearSigners: identity,
        SignersCleared: state => (Object.assign(Object.assign({}, state), { signers: [] })),
        SignatureRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        SignatureRequestDone: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)) })),
        SignatureRequestFailed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        cancelSignature: (state, _) => state,
        SignatureCancellationRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        SignatureCancelled: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)) })),
        SignatureNotCancelled: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        signDocument: (state, _) => state,
        uploadFiles: (state, _) => state,
        fetchPolicyInstitutes: (state, _) => state,
        PolicyInstitutesRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyInstitute: undefined }), loading: Object.assign(Object.assign({}, state.loading), { policy: true }) })),
        PolicyInstitutesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { policy: false }), fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyInstitute: undefined }), policy: Object.assign(Object.assign({}, state.policy), { institutes: event.payload.institutes.map(({ name }) => name) }) })),
        PolicyInstitutesNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyInstitute: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { policy: false }) })),
        fetchPolicyProducts: (state, _) => state,
        PolicyProductsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyProduct: undefined }), loading: Object.assign(Object.assign({}, state.loading), { policy: true }) })),
        PolicyProductsFetched: policyProductsFetched,
        PolicyProductsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyProduct: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { policy: false }) })),
        fetchPolicyTypesAndProducts: (state, _) => state,
        PolicyTypeAndProductsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyProduct: undefined }), loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PolicyTypeAndProductsFetched: policyTypeAndProductsFetched,
        PolicyTypeAndProductsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyProduct: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        deleteSection: (state, _) => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { policyProduct: GENERIC_ERROR }) })),
        SectionDeletionRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        SectionNotDeleted: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        SectionDeleted: (state, event) => {
            const draft = {};
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(null, event.payload.paperwork, event.payload.permissions, draft), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), draft: draft });
        },
        setDraft: (state, command) => setDraft(state, command),
        saveLoanForm: (state, _) => state,
        LoanFormSaved: (state, event) => (Object.assign(Object.assign({}, state), { detail: state.detail !== null
                ? detailStateModelMapper({ typology: 'loan', data: event.payload.loanFormData }, event.payload.paperwork, event.payload.permissions, Object.assign({}, state.draft), event.payload.products)
                : null })),
        saveMortgageForm: (state, _) => state,
        MortgageFormSaved: (state, event) => (Object.assign(Object.assign({}, state), { detail: state.detail !== null
                ? detailStateModelMapper({ typology: 'mortgage', data: event.payload.mortgageFormData }, event.payload.paperwork, event.payload.permissions, Object.assign({}, state.draft), event.payload.products)
                : null })),
        FileUploadRequested: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: replaceOrAddFile({
                    type: event.payload.file.type,
                    size: event.payload.file.size,
                    name: event.payload.file.name,
                    isLoading: true,
                    hasError: false,
                    isUploaded: false,
                    uploadedAt: null,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        deleteFile: (state, _) => state,
        FileDeletionRequested: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: replaceOrAddFile({
                    id: event.payload.documentToDelete.id,
                    type: event.payload.documentToDelete.type,
                    size: event.payload.documentToDelete.size,
                    name: event.payload.documentToDelete.name,
                    isLoading: true,
                    hasError: false,
                    isUploaded: false,
                    uploadedAt: null,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileDeleted: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: _removeFile({
                    id: event.payload.deletedDocument.id,
                    type: event.payload.deletedDocument.type,
                    size: event.payload.deletedDocument.size,
                    name: event.payload.deletedDocument.name,
                    isLoading: false,
                    hasError: false,
                    isUploaded: false,
                    uploadedAt: null,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileNotDeleted: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: replaceOrAddFile({
                    id: event.payload.documentToDelete.id,
                    type: event.payload.documentToDelete.type,
                    size: event.payload.documentToDelete.size,
                    name: event.payload.documentToDelete.name,
                    isLoading: false,
                    hasError: true,
                    isUploaded: true,
                    uploadedAt: null,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileUploaded: (state, event) => (Object.assign(Object.assign({}, state), { documentSection: Object.assign(Object.assign({}, state.documentSection), { uploadedDocuments: state.documentSection.uploadedDocuments +
                    uploadedDocumentsMapper(state.files, event.payload.documentTypeId) }), files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: replaceOrAddFile({
                    id: event.payload.documentation.id,
                    type: event.payload.documentation.file.mimeType,
                    size: event.payload.documentation.file.size,
                    name: event.payload.documentation.file.name,
                    isLoading: false,
                    hasError: false,
                    isUploaded: true,
                    uploadedAt: new Date().toISOString(),
                    transitionsHistory: event.payload.documentation.transitionsHistory,
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileNotUploaded: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: replaceOrAddFile({
                    type: event.payload.file.type,
                    size: event.payload.file.size,
                    name: event.payload.file.name,
                    isLoading: false,
                    hasError: true,
                    isUploaded: false,
                    uploadedAt: null,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        ProcessPaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        PaperworkProcessed: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), permissions: Object.assign(Object.assign({}, state.permissions), { viewPaperwork: true }) })),
        PaperworkNotProcessed: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), permissions: Object.assign(Object.assign({}, state.permissions), { viewPaperwork: event.payload.viewPaperwork !== undefined
                    ? event.payload.viewPaperwork
                    : state.permissions.viewPaperwork }) })),
        CommissionsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: true }) })),
        CommissionsFetched: (state, event) => (Object.assign(Object.assign({}, state), { commissions: Object.assign(Object.assign({}, state.commissions), { table: commissionsTableModelMapper(event.payload.commissions), total: commissionsTotalStateMapper(event.payload.commissions) }), loading: Object.assign(Object.assign({}, state.loading), { commissions: false }) })),
        CommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: false }), commissions: { table: { body: [], headers: [] }, total: null, error: event.payload.error.message } })),
        FormFillRequested: identity,
        FormNotFilled: identity,
        FormFilled: (state, event) => (Object.assign(Object.assign({}, state), { initialValues: Object.assign(Object.assign({}, state.initialValues), { loan: event.payload.productTypology === 'loan'
                    ? loanFormStateModelMapper(event.payload.paperwork.data, state.draft)
                    : null, mortgage: event.payload.productTypology === 'mortgage'
                    ? mortgageFormStateModelMapper(event.payload.paperwork.data, state.draft)
                    : null, cqs: event.payload.productTypology === 'cqs'
                    ? cqsFormStateModelMapper(event.payload.paperwork.data)
                    : null, policy: event.payload.productTypology === 'policy'
                    ? standalonePolicyFormStateModelMapper(event.payload.paperwork.data)
                    : null }) })),
        PaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkFetched: (state, event) => {
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), products: event.payload.products || state.products, paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(null, event.payload.paperwork, event.payload.permissions, Object.assign({}, state.draft), event.payload.products), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), permissions: Object.assign(Object.assign({}, state.permissions), { viewPaperwork: true }) });
        },
        PaperworkNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), permissions: Object.assign(Object.assign({}, state.permissions), { viewPaperwork: event.payload.viewPaperwork !== undefined
                    ? event.payload.viewPaperwork
                    : state.permissions.viewPaperwork }) })),
        PaperworkAndDocumentationUpdateRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkAndDocumentationUpdated: (state, event) => {
            const draft = {};
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(null, event.payload.paperwork, event.payload.permissions, draft), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), draft: draft, initialValues: Object.assign(Object.assign({}, state.initialValues), { loan: event.payload.paperwork.productTypology === 'loan'
                        ? loanFormStateModelMapper(event.payload.paperwork.data, draft)
                        : null, mortgage: event.payload.paperwork.productTypology === 'mortgage'
                        ? mortgageFormStateModelMapper(event.payload.paperwork.data, draft)
                        : null, cqs: event.payload.paperwork.productTypology === 'cqs'
                        ? cqsFormStateModelMapper(event.payload.paperwork.data)
                        : null, policy: event.payload.paperwork.productTypology === 'policy'
                        ? standalonePolicyFormStateModelMapper(event.payload.paperwork.data)
                        : null }) });
        },
        PaperworkAndDocumentationNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        LocationRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: undefined }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: true }) })),
        LocationFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }), participantPlaceOfResidence: event.payload.participantPlaceOfResidence })),
        LocationNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }) })),
        ProductsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: undefined }), loading: Object.assign(Object.assign({}, state.loading), { product: true }) })),
        ProductsFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: event.payload.products })),
        ProductsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: [] })),
        PurposesRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { purpose: undefined }), loading: Object.assign(Object.assign({}, state.loading), { purpose: true }) })),
        PurposesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { purpose: false }), purposes: event.payload.purposes })),
        PurposesNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { purpose: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { purpose: false }), purposes: [] })),
        InstitutesRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: undefined }), loading: Object.assign(Object.assign({}, state.loading), { institute: true }) })),
        InstitutesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: event.payload.institutes })),
        InstitutesNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: [] })),
        ChannelsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: undefined }), loading: Object.assign(Object.assign({}, state.loading), { channel: true }) })),
        ChannelsFetched: (state, event) => (Object.assign(Object.assign({}, state), { channels: event.payload.channels.map(channelStateMapper), loading: Object.assign(Object.assign({}, state.loading), { channel: false }) })),
        ChannelsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { channel: false }), channels: [] })),
        StoresRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { store: true }), fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: undefined }) })),
        StoresFetched: (state, event) => (Object.assign(Object.assign({}, state), { stores: event.payload.stores, loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        StoresNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        compilePaperwork: identity,
        CompilePaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkCompiled: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(null, event.payload.paperwork, event.payload.permissions, Object.assign({}, state.draft), state.products), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)) })),
        PaperworkNotCompiled: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        Started: identity,
        Stopped: identity,
    },
});
export const $PaperworkProcess = paperworkProcessSlice.actions;
export const $PaperworkProcessSlice = {
    selector: {
        detail: (state) => state.paperworkProcess.detail,
        paperwork: (state) => state.paperworkProcess.paperworkDetail,
        initialValues: (state) => state.paperworkProcess.initialValues,
        loading: (state) => state.paperworkProcess.loading,
        errors: (state) => state.paperworkProcess.errors,
        stores: (state) => state.paperworkProcess.stores,
        channels: (state) => state.paperworkProcess.channels,
        institutes: (state) => state.paperworkProcess.institutes,
        purposes: (state) => state.paperworkProcess.purposes,
        products: (state) => state.paperworkProcess.products,
        participantPlaceOfResidence: (state) => state.paperworkProcess.participantPlaceOfResidence,
        commissions: (state) => state.paperworkProcess.commissions,
        isPageLoading: (state) => state.paperworkProcess.loading.processPaperwork ||
            state.paperworkProcess.loading.commissions ||
            state.paperworkProcess.loading.paperwork,
        documentSection: (state) => state.paperworkProcess.documentSection,
        fieldErrors: (state) => state.paperworkProcess.fieldErrors,
        files: (state) => state.paperworkProcess.files,
        signers: (state) => state.paperworkProcess.signers,
        draftById: (state) => (id) => state.paperworkProcess.draft[id] || false,
        draft: (state) => state.paperworkProcess.draft,
        policy: (state) => state.paperworkProcess.policy,
        permissions: (state) => state.paperworkProcess.permissions,
    },
};
export const PaperworkProcessState = paperworkProcessSlice.reducer;
