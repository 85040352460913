import { notInAgreementInstitutes } from '../../../models/InstituteModel';
export const institutesModelMapper = (productTypology) => (institutes) => {
    let institutesModel = institutes.map(institute => ({
        name: institute.name,
        affiliated: institute.affiliated,
    }));
    if (productTypology === 'mortgage') {
        institutesModel = [...institutesModel, ...notInAgreementInstitutes];
    }
    return institutesModel;
};
