import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
export class DeleteMortgageSectionMapper {
    static getPaperworkIndexOption(index, partialKey) {
        switch (index) {
            case this.FIRST_APPLICANT_INDEX:
                return option.some(partialKey);
            case this.SECOND_APPLICANT_INDEX:
                return option.some(`intestatario_0_${partialKey}`);
            case this.THIRD_APPLICANT_INDEX:
                return option.some(`intestatario_1_${partialKey}`);
            case this.FOURTH_APPLICANT_INDEX:
                return option.some(`intestatario_2_${partialKey}`);
            case this.FIRST_GUARANTOR_INDEX:
                return option.some(`garante_0_${partialKey}`);
            case this.SECOND_GUARANTOR_INDEX:
                return option.some(`garante_1_${partialKey}`);
            case this.FIRST_MORTGAGER_INDEX:
                return option.some(`datore_0_${partialKey}`);
            case this.SECOND_MORTGAGER_INDEX:
                return option.some(`datore_1_${partialKey}`);
            case this.FIRST_POLICY_INDEX:
                return option.some(`polizza_assicurativa_${partialKey}`);
            case this.SECOND_POLICY_INDEX:
                return option.some(`polizza_assicurativa_1_${partialKey}`);
            case this.THIRD_POLICY_INDEX:
                return option.some(`polizza_assicurativa_2_${partialKey}`);
            case this.FOURTH_POLICY_INDEX:
                return option.some(`polizza_assicurativa_3_${partialKey}`);
            case this.FIFTH_POLICY_INDEX:
                return option.some(`polizza_assicurativa_4_${partialKey}`);
            default:
                return option.none;
        }
    }
    static getParticipantKeysWithIndex(participantIndex) {
        return pipe(DeleteMortgageSectionMapper.participantDataKeys, array.map(key => DeleteMortgageSectionMapper.getPaperworkIndexOption(participantIndex, key)), array.compact);
    }
    static _removeKeys(keys, obj) {
        return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));
    }
    static _replaceKeys(currentKey, newKey, obj) {
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => (key === newKey ? [currentKey, value] : [key, value])));
    }
    static isParticipantFirstNameAvailable(participantIndex) {
        const participantOption = pipe(DeleteMortgageSectionMapper.getPaperworkIndexOption(participantIndex, 'nome'));
        return option.isSome(participantOption) && participantOption.value !== undefined;
    }
    static isPolicyTypeAvailable(policyIndex) {
        const participantOption = pipe(DeleteMortgageSectionMapper.getPaperworkIndexOption(policyIndex, 'tipo_di_polizza'));
        return option.isSome(participantOption) && participantOption.value !== undefined;
    }
    static deleteSection(index, paperworkDataModel) {
        if (index >= DeleteMortgageSectionMapper.FIRST_APPLICANT_INDEX &&
            index <= DeleteMortgageSectionMapper.SECOND_MORTGAGER_INDEX) {
            return this._deleteParticipant(index, paperworkDataModel);
        }
        if (index >= DeleteMortgageSectionMapper.FIRST_POLICY_INDEX &&
            index <= DeleteMortgageSectionMapper.FIFTH_POLICY_INDEX) {
            return this._deletePolicy(index, paperworkDataModel);
        }
        return paperworkDataModel;
    }
    static _deletePolicy(policyIndex, paperworkDataModel) {
        const keysWithIndex = pipe(DeleteMortgageSectionMapper.policyDataKeys, array.map(key => DeleteMortgageSectionMapper.getPaperworkIndexOption(policyIndex, key)), array.compact);
        const policyWithKey = (policyNumber, key) => policyNumber === 0 ? `polizza_assicurativa_${key}` : `polizza_assicurativa_${policyNumber}_${key}`;
        let paperworkData = {};
        paperworkData = DeleteMortgageSectionMapper._removeKeys(keysWithIndex, paperworkDataModel);
        switch (policyIndex) {
            case this.FIRST_POLICY_INDEX:
                if (this.isPolicyTypeAvailable(this.SECOND_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(0, key), policyWithKey(1, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.THIRD_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(1, key), policyWithKey(2, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.FOURTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(2, key), policyWithKey(3, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.FIFTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(3, key), policyWithKey(4, key), paperworkData);
                    });
                }
                return paperworkData;
            case this.SECOND_POLICY_INDEX:
                if (this.isPolicyTypeAvailable(this.THIRD_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(1, key), policyWithKey(2, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.FOURTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(2, key), policyWithKey(3, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.FIFTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(3, key), policyWithKey(4, key), paperworkData);
                    });
                }
                return paperworkData;
            case this.THIRD_POLICY_INDEX:
                if (this.isPolicyTypeAvailable(this.FOURTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(2, key), policyWithKey(3, key), paperworkData);
                    });
                }
                if (this.isPolicyTypeAvailable(this.FIFTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(3, key), policyWithKey(4, key), paperworkData);
                    });
                }
                return paperworkData;
            case this.FOURTH_POLICY_INDEX:
                if (this.isPolicyTypeAvailable(this.FIFTH_POLICY_INDEX)) {
                    this.policyDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(policyWithKey(3, key), policyWithKey(4, key), paperworkData);
                    });
                }
                return paperworkData;
        }
        return paperworkData;
    }
    static _deleteParticipant(participantIndex, paperworkDataModel) {
        let paperworkData = {};
        const keysWithIndex = this.getParticipantKeysWithIndex(participantIndex);
        paperworkData = this._removeKeys(keysWithIndex, paperworkDataModel);
        switch (participantIndex) {
            case this.FIRST_GUARANTOR_INDEX:
                if (this.isParticipantFirstNameAvailable(this.SECOND_GUARANTOR_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`garante_0_${key}`, `garante_1_${key}`, paperworkData);
                    });
                }
                return paperworkData;
            case this.FIRST_MORTGAGER_INDEX:
                if (this.isParticipantFirstNameAvailable(this.SECOND_MORTGAGER_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`datore_0_${key}`, `datore_1_${key}`, paperworkData);
                    });
                }
                return paperworkData;
            case this.FIRST_APPLICANT_INDEX:
                if (this.isParticipantFirstNameAvailable(this.SECOND_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`${key}`, `intestatario_0_${key}`, paperworkData);
                    });
                }
                if (this.isParticipantFirstNameAvailable(this.THIRD_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`intestatario_0_${key}`, `intestatario_1_${key}`, paperworkData);
                    });
                }
                if (this.isParticipantFirstNameAvailable(this.FOURTH_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                    });
                }
                return paperworkData;
            case this.SECOND_APPLICANT_INDEX:
                if (this.isParticipantFirstNameAvailable(this.THIRD_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`intestatario_0_${key}`, `intestatario_1_${key}`, paperworkData);
                    });
                }
                if (this.isParticipantFirstNameAvailable(this.FOURTH_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                    });
                }
                return paperworkData;
            case this.THIRD_APPLICANT_INDEX:
                if (this.isParticipantFirstNameAvailable(this.FOURTH_APPLICANT_INDEX)) {
                    this.participantDataKeys.forEach(key => {
                        paperworkData = this._replaceKeys(`intestatario_1_${key}`, `intestatario_2_${key}`, paperworkData);
                    });
                }
                return paperworkData;
        }
        return paperworkData;
    }
    static map(index, paperworkDataModel) {
        if (index >= this.FIRST_APPLICANT_INDEX && index <= this.SECOND_MORTGAGER_INDEX) {
            return this._deleteParticipant(index, paperworkDataModel);
        }
        if (index >= this.FIRST_POLICY_INDEX && index <= this.FIFTH_POLICY_INDEX) {
            return this._deletePolicy(index, paperworkDataModel);
        }
        return paperworkDataModel;
    }
}
DeleteMortgageSectionMapper.FIRST_APPLICANT_INDEX = 0;
DeleteMortgageSectionMapper.SECOND_APPLICANT_INDEX = 1;
DeleteMortgageSectionMapper.THIRD_APPLICANT_INDEX = 2;
DeleteMortgageSectionMapper.FOURTH_APPLICANT_INDEX = 3;
DeleteMortgageSectionMapper.FIRST_GUARANTOR_INDEX = 4;
DeleteMortgageSectionMapper.SECOND_GUARANTOR_INDEX = 5;
DeleteMortgageSectionMapper.FIRST_MORTGAGER_INDEX = 6;
DeleteMortgageSectionMapper.SECOND_MORTGAGER_INDEX = 7;
DeleteMortgageSectionMapper.FIRST_POLICY_INDEX = 8;
DeleteMortgageSectionMapper.SECOND_POLICY_INDEX = 9;
DeleteMortgageSectionMapper.THIRD_POLICY_INDEX = 10;
DeleteMortgageSectionMapper.FOURTH_POLICY_INDEX = 11;
DeleteMortgageSectionMapper.FIFTH_POLICY_INDEX = 12;
DeleteMortgageSectionMapper.participantDataKeys = [
    'nome',
    'cognome',
    'codice_fiscale',
    'indirizzo_di_residenza',
    'localita_di_residenza',
    'cap_di_residenza',
    'provincia_di_nascita',
    'data_di_nascita',
    'sesso',
    'tipo_di_documento',
    'cittadinanza',
    'n_di_documento',
    'rilasciato_da',
    'luogo_di_rilascio',
    'data_di_rilascio',
    'data_di_scadenza',
    'telefono_cellulare',
    'telefono_fisso',
    'email',
    'stato_civile',
    'professione',
    'tipo_di_azienda',
    'partita_iva_datore_di_lavoro',
    'reddito_mensile_netto',
    'numero_di_mensilita',
    'anzianita_lavorativa',
    'componenti_nucleo_familiare',
    'consenso_invio_comunicazioni_promozionali_e_marketing',
    'consenso_cessione_dati_a_terzi',
    'provincia_di_residenza',
    'nome_e_cognome',
    'luogo_di_nascita',
    'comune_di_residenza',
    'comune_di_nascita',
];
DeleteMortgageSectionMapper.policyDataKeys = [
    'compagnia_assicurativa',
    'importo_di_brokeraggio',
    'prodotto',
    'tipo_di_polizza',
    'tipo_di_premio',
    'valore_della_polizza',
    'tipo_di_copertura',
];
