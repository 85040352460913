import { format } from 'date-fns';
import { _canBeDeleted, FIRST_APPLICANT_INDEX, LOAN_DETAIL_STATE_MODEL, SECOND_APPLICANT_INDEX, } from '../PaperworkProcessState';
import { convertToNumber } from '../utils/convertToNumber';
const participant = (dataModelPrefixKey, canBeDeleted, index, paperworkDataModel) => {
    const getKey = (key) => (index === FIRST_APPLICANT_INDEX ? key : `${dataModelPrefixKey}_${key}`);
    const firstName = paperworkDataModel[getKey('nome')];
    if (typeof firstName === 'string') {
        return {
            type: 'applicant',
            index: index,
            canBeDeleted: canBeDeleted,
            firstName: firstName,
            lastName: paperworkDataModel[getKey('cognome')],
            residence: {
                address: paperworkDataModel[getKey('indirizzo_di_residenza')] || '',
                locationInput: paperworkDataModel[getKey('localita_di_residenza')] || '',
                location: {
                    name: paperworkDataModel[getKey('comune_di_residenza')] || '',
                    province: {
                        shortName: paperworkDataModel[getKey('provincia_di_residenza')] ||
                            '',
                    },
                },
                postalCode: paperworkDataModel[getKey('cap_di_residenza')] || '',
            },
            cityOfBirth: paperworkDataModel[getKey('comune_di_nascita')] || '',
            provinceOfBirth: paperworkDataModel[getKey('provincia_di_nascita')] || '',
            birthday: paperworkDataModel[getKey('data_di_nascita')] || '',
            gender: paperworkDataModel[getKey('sesso')] || '',
            fiscalCode: paperworkDataModel[getKey('codice_fiscale')],
            identityDocument: {
                type: paperworkDataModel[getKey('tipo_di_documento')] || '',
                code: paperworkDataModel[getKey('n_di_documento')] || '',
                issuedBy: paperworkDataModel[getKey('rilasciato_da')] || '',
                placeOfIssue: paperworkDataModel[getKey('luogo_di_rilascio')] || '',
                issueDate: paperworkDataModel[getKey('data_di_rilascio')] || '',
                expiryDate: paperworkDataModel[getKey('data_di_scadenza')] || '',
            },
            contacts: {
                mobilePhone: paperworkDataModel[getKey('telefono_cellulare')] || '',
                landline: paperworkDataModel[getKey('telefono_fisso')] || '',
                email: paperworkDataModel[getKey('email')],
            },
            citizenship: paperworkDataModel[getKey('cittadinanza')] || '',
            maritalStatus: paperworkDataModel[getKey('stato_civile')] || '',
            job: paperworkDataModel[getKey('professione')] || '',
            companyType: paperworkDataModel[getKey('tipo_di_azienda')] || '',
            employerVatNumber: paperworkDataModel[getKey('partita_iva_datore_di_lavoro')] || '',
            netIncome: paperworkDataModel[getKey('reddito_mensile_netto')] || '',
            monthlyInstallment: paperworkDataModel[getKey('numero_di_mensilita')] || '',
            lengthOfService: paperworkDataModel[getKey('anzianita_lavorativa')] || '',
            household: paperworkDataModel[getKey('componenti_nucleo_familiare')] || '',
            marketing: paperworkDataModel[getKey('consenso_invio_comunicazioni_promozionali_e_marketing')] || false,
            thirdParties: paperworkDataModel[getKey('consenso_cessione_dati_a_terzi')] ||
                false,
        };
    }
    return null;
};
export const _policy = (dataModelPrefixKey, index, paperworkDataModel) => {
    const type = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_polizza`];
    if (type === undefined) {
        return null;
    }
    const institute = paperworkDataModel[`${dataModelPrefixKey}_compagnia_assicurativa`];
    const brokerageAmount = paperworkDataModel[`${dataModelPrefixKey}_importo_di_brokeraggio`];
    const product = paperworkDataModel[`${dataModelPrefixKey}_prodotto`];
    const typeOfCoverage = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_copertura`];
    const amount = paperworkDataModel[`${dataModelPrefixKey}_valore_della_polizza`];
    const premiumType = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_premio`];
    return {
        typeOfCoverage: typeOfCoverage,
        amount: amount,
        brokerageAmount: brokerageAmount,
        index: index,
        institute: institute,
        premiumType: premiumType,
        product: product,
        type: type,
    };
};
export const loanFormStateModelMapper = (paperworkDataModel, draft) => {
    var _a;
    return {
        operationalData: {
            warrantStartDate: paperworkDataModel.data_firma_mandato || '',
            warrantEndDate: paperworkDataModel.data_scadenza_mandato || '',
            warrantEndLabel: paperworkDataModel.data_scadenza_mandato
                ? format(paperworkDataModel.data_scadenza_mandato, 'dd/MM/yyyy')
                : '',
            expectedLendingDate: paperworkDataModel.data_erogazione_finanziamento || '',
            lendingPaymentMethod: paperworkDataModel.mezzo_di_pagamento_finanziamento || '',
            store: paperworkDataModel.store || '',
            storeInput: paperworkDataModel.store || '',
            channelInput: paperworkDataModel.rubinetto,
            channel: paperworkDataModel.classe_rubinetto,
        },
        participants: [
            participant('', _canBeDeleted(draft) && typeof paperworkDataModel.intestatario_0_nome === 'string', FIRST_APPLICANT_INDEX, paperworkDataModel),
            participant('intestatario_0', _canBeDeleted(draft), SECOND_APPLICANT_INDEX, paperworkDataModel),
        ].filter(participants => participants !== null),
        loan: {
            purpose: paperworkDataModel.prestito_finalita || '',
            institute: paperworkDataModel.prestito_finanziaria || '',
            product: paperworkDataModel.prestito_prodotto || '',
            requestedAmount: convertToNumber(paperworkDataModel.prestito_importo_richiesto),
            duration: convertToNumber(paperworkDataModel.prestito_durata_mesi),
            customerType: paperworkDataModel.prestito_tipo_di_cliente || '',
            tan: convertToNumber(paperworkDataModel.prestito_taeg),
            processingFee: convertToNumber(paperworkDataModel.prestito_spese_istruttoria),
            installment: convertToNumber(paperworkDataModel.prestito_rata),
            cpiList: ((_a = paperworkDataModel.prestito_cpi) === null || _a === void 0 ? void 0 : _a.split(',').map(cpi => cpi.trim())) || [],
            hasCpi: paperworkDataModel.prestito_cpi_cofidis || false,
            policyRequestedAmount: convertToNumber(paperworkDataModel.prestito_cpi_cofidis_importo_richiesto),
            notes: paperworkDataModel.note || '',
        },
        policies: [
            _policy('polizza_assicurativa', LOAN_DETAIL_STATE_MODEL.POLICIES.FIRST_POLICY, paperworkDataModel),
            _policy('polizza_assicurativa_1', LOAN_DETAIL_STATE_MODEL.POLICIES.SECOND_POLICY, paperworkDataModel),
            _policy('polizza_assicurativa_2', LOAN_DETAIL_STATE_MODEL.POLICIES.THIRD_POLICY, paperworkDataModel),
            _policy('polizza_assicurativa_3', LOAN_DETAIL_STATE_MODEL.POLICIES.FOURTH_POLICY, paperworkDataModel),
            _policy('polizza_assicurativa_4', LOAN_DETAIL_STATE_MODEL.POLICIES.FIFTH_POLICY, paperworkDataModel),
        ].filter(policy => policy !== null),
    };
};
