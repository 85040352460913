import { format } from 'date-fns';
import { numericFormatter } from 'react-number-format';
const currencyFormatterRules = {
    thousandSeparator: '.',
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    decimalSeparator: ',',
};
const getTableDetail = (paperwork) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    switch (paperwork.productType) {
        case 'cqs':
            return {
                typology: 'CQS',
                institute: (_a = paperwork.data.cessione_del_quinto_finanziaria) !== null && _a !== void 0 ? _a : '',
                product: (_b = paperwork.data.cessione_del_quinto_prodotto) !== null && _b !== void 0 ? _b : '',
                purpose: (_c = paperwork.data.cessione_del_quinto_finalita) !== null && _c !== void 0 ? _c : '',
                amount: paperwork.data.cessione_del_quinto_importo_richiesto
                    ? `${numericFormatter(paperwork.data.cessione_del_quinto_importo_richiesto.toString(), currencyFormatterRules)} €`
                    : '',
            };
        case 'policy':
            return {
                typology: 'Polizza',
                institute: (_d = paperwork.data.polizza_assicurativa_compagnia_assicurativa) !== null && _d !== void 0 ? _d : '',
                product: (_e = paperwork.data.polizza_assicurativa_prodotto) !== null && _e !== void 0 ? _e : '',
                purpose: '',
                amount: paperwork.data.polizza_assicurativa_importo_richiesto
                    ? `${numericFormatter(paperwork.data.polizza_assicurativa_importo_richiesto.toString(), currencyFormatterRules)} €`
                    : '',
            };
        case 'loan':
            return {
                typology: 'Prestito',
                institute: (_f = paperwork.data.prestito_finanziaria) !== null && _f !== void 0 ? _f : '',
                product: (_g = paperwork.data.prestito_prodotto) !== null && _g !== void 0 ? _g : '',
                purpose: (_h = paperwork.data.prestito_finalita) !== null && _h !== void 0 ? _h : '',
                amount: paperwork.data.prestito_importo_richiesto
                    ? `${numericFormatter(paperwork.data.prestito_importo_richiesto.toString(), currencyFormatterRules)} €`
                    : '',
            };
        case 'mortgage':
            return {
                typology: 'Mutuo',
                institute: (_j = paperwork.data.mutuo_istituto) !== null && _j !== void 0 ? _j : '',
                product: (_k = paperwork.data.mutuo_prodotto) !== null && _k !== void 0 ? _k : '',
                purpose: (_l = paperwork.data.mutuo_finalita) !== null && _l !== void 0 ? _l : '',
                amount: paperwork.data.mutuo_importo_richiesto
                    ? `${numericFormatter(paperwork.data.mutuo_importo_richiesto.toString(), currencyFormatterRules)} €`
                    : '',
            };
    }
};
export const tableStateModelMapper = (paperworkList) => {
    return paperworkList.map(paperwork => (Object.assign({ assignedTo: `${paperwork.assignedTo.firstName} ${paperwork.assignedTo.lastName}`, id: paperwork.id, status: paperwork.currentStatus.label, createdAt: format(paperwork.createdAt, 'dd/MM/yyyy'), name: `${paperwork.data.nome} ${paperwork.data.cognome}` }, getTableDetail(paperwork))));
};
