import { createSlice } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/function';
import { paginationStateModelMapper } from './mappers/paginationStateModelMapper';
import { tableStateModelMapper } from './mappers/tableStateModelMapper';
const initialStateTablePagination = {
    perPage: 10,
    skip: 0,
    currentPage: 0,
    isNextPageDisabled: false,
    isPreviousPageDisabled: false,
};
const initialState = {
    loading: { paperworkList: false },
    numberOfPaperwork: null,
    paperworkList: [],
    table: null,
    pagination: initialStateTablePagination,
    appliedFilters: { productType: '', customerFirstName: '', customerLastName: '', id: '', product: '' },
};
export const paperworkListSlice = createSlice({
    name: 'PaperworkListSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        fetchPaperworkList: (state, _) => state,
        fetchNextPage: (state, _) => state,
        fetchPreviousPage: (state, _) => state,
        fetchFirstPage: (state, _) => state,
        PaperworkListRequested: state => (Object.assign(Object.assign({}, state), { numberOfPaperwork: null, loading: Object.assign(Object.assign({}, state.loading), { paperworkList: true }) })),
        PaperworkListFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperworkList: false }), numberOfPaperwork: event.payload.paperworkList.total, table: tableStateModelMapper(event.payload.paperworkList.items), pagination: paginationStateModelMapper(event.payload.paperworkList, event.payload.pagination), appliedFilters: Object.assign(Object.assign({}, state.appliedFilters), event.payload.filter) })),
        PaperworkListNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperworkList: false }) })),
        Started: identity,
        Stopped: identity,
    },
});
export const $PaperworkList = paperworkListSlice.actions;
export const $PaperworkListSlice = {
    selector: {
        appliedFilters: (state) => state.paperworkList.appliedFilters,
        pagination: (state) => state.paperworkList.pagination,
        table: (state) => state.paperworkList.table,
        paperworkList: (state) => state.paperworkList.paperworkList,
        numberOfPaperwork: (state) => state.paperworkList.numberOfPaperwork,
        isPaperworkListLoading: (state) => state.paperworkList.loading.paperworkList,
    },
};
export const PaperworkListState = paperworkListSlice.reducer;
