import { option, readonlyArray } from 'fp-ts';
import { pipe } from 'fp-ts/function';
export class UpdatePaperworkMapper {
    static mapCityAndProvinceShortName(fieldValue) {
        return pipe(fieldValue.split(/^(.*)[\s]\((.+)\)$/), // example: xxx (xxx)
        option.fromNullable, option.bindTo('placeOfResidenceSplit'), option.bind('city', ({ placeOfResidenceSplit }) => pipe(placeOfResidenceSplit, readonlyArray.lookup(1))), option.bind('provinceShortName', ({ placeOfResidenceSplit }) => pipe(placeOfResidenceSplit, readonlyArray.lookup(2))), option.map(({ city, provinceShortName }) => ({ city, provinceShortName })));
    }
    static sanitizeFlatRecord(r) {
        return Object.fromEntries(Object.entries(r).filter(([_, value]) => value !== '' && value !== null));
    }
}
