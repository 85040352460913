import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
import { recordListFetched } from './reducers/recordListFetched';
const initialState = {
    loading: false,
    error: option.none,
    table: { body: [], headers: [] },
    initialValues: { firstName: '', lastName: '' },
};
export const RecordListWithPayinSlice = createSlice({
    name: 'RecordListWithPayinSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchRecordList: (state, _) => state,
        RecordListRequested: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: true, table: initialState.table })),
        RecordListFetched: (state, event) => recordListFetched(state, event),
        RecordListNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: false, error: option.some(event.payload), table: initialState.table })),
    },
});
export const $RecordListWithPayin = RecordListWithPayinSlice.actions;
const initialValues = (state) => state.recordListWithPayin.initialValues;
const isLoading = (state) => state.recordListWithPayin.loading;
const table = (state) => state.recordListWithPayin.table;
export const $RecordListWithPayinSlice = {
    selector: { initialValues, isLoading, table },
};
export default RecordListWithPayinSlice.reducer;
