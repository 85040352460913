import { format } from 'date-fns';
import { _canBeDeleted, FIFTH_POLICY_INDEX, FIRST_APPLICANT_INDEX, FIRST_GUARANTOR_INDEX, FIRST_MORTGAGER_INDEX, FIRST_POLICY_INDEX, FOURTH_APPLICANT_INDEX, FOURTH_POLICY_INDEX, SECOND_APPLICANT_INDEX, SECOND_GUARANTOR_INDEX, SECOND_MORTGAGER_INDEX, SECOND_POLICY_INDEX, THIRD_APPLICANT_INDEX, THIRD_POLICY_INDEX, } from '../PaperworkProcessState';
import { convertToNumber } from '../utils/convertToNumber';
import { minimumBrokerageAmountSuggested } from '../utils/minimumBrokerageAmountSuggested';
const ltv = (ltvData) => {
    const ltvValue = convertToNumber(ltvData);
    if (ltvValue === '') {
        return 0;
    }
    return ltvValue;
};
const participant = (dataModelPrefixKey, type, canBeDeleted, index, paperworkDataModel) => {
    const getKey = (key) => type === 'applicant' && index === FIRST_APPLICANT_INDEX ? key : `${dataModelPrefixKey}_${key}`;
    const firstName = paperworkDataModel[getKey('nome')];
    if (typeof firstName === 'string') {
        return {
            type: type,
            index: index,
            canBeDeleted: canBeDeleted,
            firstName: firstName,
            lastName: paperworkDataModel[getKey('cognome')],
            residence: {
                address: paperworkDataModel[getKey('indirizzo_di_residenza')] || '',
                locationInput: paperworkDataModel[getKey('localita_di_residenza')] || '',
                location: {
                    name: paperworkDataModel[getKey('comune_di_residenza')] || '',
                    province: {
                        shortName: paperworkDataModel[getKey('provincia_di_residenza')] || '',
                    },
                },
                postalCode: paperworkDataModel[getKey('cap_di_residenza')] || '',
            },
            cityOfBirth: paperworkDataModel[getKey('comune_di_nascita')] || '',
            provinceOfBirth: paperworkDataModel[getKey('provincia_di_nascita')] || '',
            birthday: paperworkDataModel[getKey('data_di_nascita')] || '',
            gender: paperworkDataModel[getKey('sesso')] || '',
            fiscalCode: paperworkDataModel[getKey('codice_fiscale')],
            identityDocument: {
                type: paperworkDataModel[getKey('tipo_di_documento')] || '',
                code: paperworkDataModel[getKey('n_di_documento')] || '',
                issuedBy: paperworkDataModel[getKey('rilasciato_da')] || '',
                placeOfIssue: paperworkDataModel[getKey('luogo_di_rilascio')] || '',
                issueDate: paperworkDataModel[getKey('data_di_rilascio')] || '',
                expiryDate: paperworkDataModel[getKey('data_di_scadenza')] || '',
            },
            contacts: {
                mobilePhone: paperworkDataModel[getKey('telefono_cellulare')] || '',
                landline: paperworkDataModel[getKey('telefono_fisso')] || '',
                email: paperworkDataModel[getKey('email')],
            },
            citizenship: paperworkDataModel[getKey('cittadinanza')] || '',
            maritalStatus: paperworkDataModel[getKey('stato_civile')] || '',
            job: paperworkDataModel[getKey('professione')] || '',
            companyType: paperworkDataModel[getKey('tipo_di_azienda')] || '',
            employerVatNumber: paperworkDataModel[getKey('partita_iva_datore_di_lavoro')] || '',
            netIncome: paperworkDataModel[getKey('reddito_mensile_netto')] || '',
            monthlyInstallment: paperworkDataModel[getKey('numero_di_mensilita')] || '',
            lengthOfService: paperworkDataModel[getKey('anzianita_lavorativa')] || '',
            household: paperworkDataModel[getKey('componenti_nucleo_familiare')] || '',
            marketing: paperworkDataModel[getKey('consenso_invio_comunicazioni_promozionali_e_marketing')] || false,
            thirdParties: paperworkDataModel[getKey('consenso_cessione_dati_a_terzi')] ||
                false,
        };
    }
    return null;
};
export const _policy = (dataModelPrefixKey, index, paperworkDataModel) => {
    const type = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_polizza`];
    if (type === undefined) {
        return null;
    }
    const institute = paperworkDataModel[`${dataModelPrefixKey}_compagnia_assicurativa`];
    const brokerageAmount = paperworkDataModel[`${dataModelPrefixKey}_importo_di_brokeraggio`];
    const product = paperworkDataModel[`${dataModelPrefixKey}_prodotto`];
    const typeOfCoverage = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_copertura`];
    const amount = paperworkDataModel[`${dataModelPrefixKey}_valore_della_polizza`];
    const premiumType = paperworkDataModel[`${dataModelPrefixKey}_tipo_di_premio`];
    return {
        typeOfCoverage: typeOfCoverage,
        amount: amount,
        brokerageAmount: brokerageAmount,
        index: index,
        institute: institute,
        premiumType: premiumType,
        product: product,
        type: type,
    };
};
export const mortgageFormStateModelMapper = (paperworkDataModel, draft) => {
    return {
        operationalData: {
            warrantStartDate: paperworkDataModel.data_firma_mandato || '',
            warrantEndDate: paperworkDataModel.data_scadenza_mandato || '',
            warrantEndLabel: paperworkDataModel.data_scadenza_mandato
                ? format(paperworkDataModel.data_scadenza_mandato, 'dd/MM/yyyy')
                : '',
            expectedLendingDate: paperworkDataModel.data_erogazione_finanziamento || '',
            lendingPaymentMethod: paperworkDataModel.mezzo_di_pagamento_finanziamento || '',
            store: paperworkDataModel.store || '',
            storeInput: paperworkDataModel.store || '',
            channelInput: paperworkDataModel.rubinetto,
            channel: paperworkDataModel.classe_rubinetto,
        },
        participants: [
            participant('', 'applicant', _canBeDeleted(draft) &&
                (typeof paperworkDataModel.intestatario_0_nome === 'string' ||
                    typeof paperworkDataModel.intestatario_1_nome === 'string' ||
                    typeof paperworkDataModel.intestatario_2_nome === 'string'), FIRST_APPLICANT_INDEX, paperworkDataModel),
            participant('intestatario_0', 'applicant', _canBeDeleted(draft), SECOND_APPLICANT_INDEX, paperworkDataModel),
            participant('intestatario_1', 'applicant', _canBeDeleted(draft), THIRD_APPLICANT_INDEX, paperworkDataModel),
            participant('intestatario_2', 'applicant', _canBeDeleted(draft), FOURTH_APPLICANT_INDEX, paperworkDataModel),
            participant('garante_0', 'guarantor', _canBeDeleted(draft), FIRST_GUARANTOR_INDEX, paperworkDataModel),
            participant('garante_1', 'guarantor', _canBeDeleted(draft), SECOND_GUARANTOR_INDEX, paperworkDataModel),
            participant('datore_0', 'mortgager', _canBeDeleted(draft), FIRST_MORTGAGER_INDEX, paperworkDataModel),
            participant('datore_1', 'mortgager', _canBeDeleted(draft), SECOND_MORTGAGER_INDEX, paperworkDataModel),
        ].filter(participants => participants !== null),
        mortgage: {
            type: paperworkDataModel.mutuo_tipologia_di_mutuo || '',
            purpose: paperworkDataModel.mutuo_finalita || '',
            mortgageManagement: paperworkDataModel.mutuo_gestione || '',
            institute: paperworkDataModel.mutuo_istituto || '',
            product: paperworkDataModel.mutuo_prodotto || '',
            propertyValue: convertToNumber(paperworkDataModel.mutuo_valore_immobile),
            requestedAmount: convertToNumber(paperworkDataModel.importo_richiesto),
            outstandingPrincipalAmount: convertToNumber(paperworkDataModel.mutuo_capitale_residuo),
            processingFee: convertToNumber(paperworkDataModel.mutuo_importo_istruttoria),
            renovationAmount: convertToNumber(paperworkDataModel.mutuo_importo_ristrutturazione),
            brokerageAmount: convertToNumber(paperworkDataModel.mutuo_importo_di_mediazione),
            suggestedBrokerageAmount: paperworkDataModel.mutuo_importo_richiesto && paperworkDataModel.mutuo_importo_di_mediazione
                ? minimumBrokerageAmountSuggested(paperworkDataModel.classe_rubinetto, paperworkDataModel.mutuo_importo_richiesto, paperworkDataModel.mutuo_importo_di_mediazione)
                : '',
            brokeragePercentage: convertToNumber(paperworkDataModel.mutuo_percentuale_mediazione),
            buildingType: paperworkDataModel.mutuo_tipo_di_immobile || '',
            energyClass: paperworkDataModel.mutuo_classe_energetica_immobile || '',
            searchStatus: paperworkDataModel.mutuo_stato_ricerca_immobile || '',
            ltv: ltv(paperworkDataModel.mutuo_ltv),
            location: paperworkDataModel.mutuo_localita_immobile || '',
            duration: paperworkDataModel.mutuo_durata_anni || '',
            rateType: paperworkDataModel.mutuo_tipo_di_tasso || '',
            spread: convertToNumber(paperworkDataModel.mutuo_spread),
            notes: paperworkDataModel.note || '',
        },
        policies: [
            _policy('polizza_assicurativa', FIRST_POLICY_INDEX, paperworkDataModel),
            _policy('polizza_assicurativa_1', SECOND_POLICY_INDEX, paperworkDataModel),
            _policy('polizza_assicurativa_2', THIRD_POLICY_INDEX, paperworkDataModel),
            _policy('polizza_assicurativa_3', FOURTH_POLICY_INDEX, paperworkDataModel),
            _policy('polizza_assicurativa_4', FIFTH_POLICY_INDEX, paperworkDataModel),
        ].filter(policy => policy !== null),
    };
};
