import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EmptyListSrc from '../../../../../assets/workInProgress.svg';
export const EmptyList = ({ message }) => {
    return (_jsxs(Grid, { container: true, alignItems: "center", direction: "column", children: [_jsx(Grid, { children: _jsx(EmptyListSrc, { style: { width: 300, height: 300 } }) }), message && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h6", style: {
                        textTransform: 'uppercase',
                        color: '#3377F6',
                    }, children: message }) }))] }));
};
