import { format } from 'date-fns';
export const standalonePolicyFormStateModelMapper = (paperworkDataModel) => {
    return {
        operationalData: {
            warrantStartDate: paperworkDataModel.data_firma_mandato || '',
            warrantEndDate: paperworkDataModel.data_scadenza_mandato || '',
            warrantEndLabel: paperworkDataModel.data_scadenza_mandato
                ? format(paperworkDataModel.data_scadenza_mandato, 'dd/MM/yyyy')
                : '',
            expectedLendingDate: paperworkDataModel.data_erogazione_finanziamento || '',
            lendingPaymentMethod: paperworkDataModel.mezzo_di_pagamento_finanziamento || '',
            store: paperworkDataModel.store || '',
            storeInput: paperworkDataModel.store || '',
            channelInput: paperworkDataModel.rubinetto,
            channel: paperworkDataModel.classe_rubinetto,
            notes: paperworkDataModel.note || '',
        },
        policy: {
            amount: paperworkDataModel.polizza_assicurativa_valore_della_polizza || '',
            institute: paperworkDataModel.polizza_assicurativa_compagnia_assicurativa || '',
            premiumType: paperworkDataModel.polizza_assicurativa_tipo_di_premio || '',
            product: paperworkDataModel.polizza_assicurativa_prodotto || '',
            type: paperworkDataModel.polizza_assicurativa_tipo_di_polizza || '',
            typeOfCoverage: paperworkDataModel.polizza_assicurativa_tipo_di_copertura || '',
            brokerageAmount: paperworkDataModel.polizza_assicurativa_importo_di_brokeraggio || '',
        },
        participant: {
            firstName: paperworkDataModel.nome,
            lastName: paperworkDataModel.cognome,
            residence: {
                address: paperworkDataModel.indirizzo_di_residenza || '',
                locationInput: paperworkDataModel.localita_di_residenza || '',
                location: {
                    name: paperworkDataModel.comune_di_residenza || '',
                    province: { shortName: paperworkDataModel.provincia_di_residenza || '' },
                },
                postalCode: paperworkDataModel.cap_di_residenza || '',
            },
            cityOfBirth: paperworkDataModel.comune_di_nascita || '',
            provinceOfBirth: paperworkDataModel.provincia_di_nascita || '',
            birthday: paperworkDataModel.data_di_nascita || '',
            gender: paperworkDataModel.sesso || '',
            fiscalCode: paperworkDataModel.codice_fiscale,
            identityDocument: {
                type: paperworkDataModel.tipo_di_documento || '',
                code: paperworkDataModel.n_di_documento || '',
                issuedBy: paperworkDataModel.rilasciato_da || '',
                placeOfIssue: paperworkDataModel.luogo_di_rilascio || '',
                issueDate: paperworkDataModel.data_di_rilascio || '',
                expiryDate: paperworkDataModel.data_di_scadenza || '',
            },
            contacts: {
                mobilePhone: paperworkDataModel.telefono_cellulare || '',
                landline: paperworkDataModel.telefono_fisso || '',
                email: paperworkDataModel.email,
            },
            citizenship: paperworkDataModel.cittadinanza || '',
            maritalStatus: paperworkDataModel.stato_civile || '',
            job: paperworkDataModel.professione || '',
            companyType: paperworkDataModel.tipo_di_azienda || '',
            employerVatNumber: paperworkDataModel.partita_iva_datore_di_lavoro || '',
            netIncome: paperworkDataModel.reddito_mensile_netto || '',
            monthlyInstallment: paperworkDataModel.numero_di_mensilita || '',
            lengthOfService: paperworkDataModel.anzianita_lavorativa || '',
            household: paperworkDataModel.componenti_nucleo_familiare || '',
            marketing: paperworkDataModel.consenso_invio_comunicazioni_promozionali_e_marketing || false,
            thirdParties: paperworkDataModel.consenso_cessione_dati_a_terzi || false,
        },
    };
};
