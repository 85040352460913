import { googleLogout } from '@react-oauth/google';
import { either, option, string } from 'fp-ts';
import { isLeft, isRight } from 'fp-ts/Either';
import { isSome } from 'fp-ts/Option';
import { Eq } from 'fp-ts/boolean';
import { constTrue, constVoid, not, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { push } from 'redux-first-history';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { SECURITY_SESSION } from '../../api/constants';
import { ALL_FILTER, ROLES, TOKEN } from '../../constants';
import { Hotjar } from '../../lib/Hotjar';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { ClientError } from '../../lib/http/ClientError';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { Process } from '../../lib/redux/Process';
import { Context } from '../../model/Context';
import { UserC } from '../../model/User';
import { $routes } from '../../routes/routes';
import { $url, BooleanQueryParameterC } from '../../utils/Url';
import { SESSION_RESPONSE_ERROR } from '../../utils/session';
import { UpdateFilterRequest } from '../actions/filter';
import { defaultAssigneeObject, defaultStatusObject } from '../state/filter';
import { SecurityActions } from './SecurityActions';
import { SecurityState } from './SecurityState';
const REDIRECT_FORCEPATH = 'forcePath';
const isProvider = (p) => p.provider !== undefined;
function* logIn(action) {
    try {
        yield* call(Context.http().post, SECURITY_SESSION, action.payload.credentials);
        const domainCode = getDomainCode(action.payload.location.pathname);
        const { data } = yield* call(Context.http().patch, SECURITY_SESSION, {
            domainCode: domainCode,
        });
        const user = UserC.decode(data);
        if (isLeft(user)) {
            throw new Error();
        }
        yield* call(Context.intercom.restart(Context.intercom.user.some(ROLES)(user.right)), window);
        yield* call(Hotjar.identifyUser, window, user.right, ROLES);
        yield* put(Process.Done(action)(user.right));
        if (isProvider(action.input.credentials)) {
            const emailEither = yield* call(Context.cache.get('email'));
            const url = yield* call(Context.cache.get('url'));
            yield* call(Context.cache.set('email', user.right.email));
            const emailOption = pipe(emailEither, option.fromEither, option.filter(t.string.is));
            const forcePathEither = yield* call(Context.cache.get('forcePath'));
            if ((isRight(forcePathEither) ||
                (isSome(emailOption) &&
                    isProvider(action.input.credentials) &&
                    string.Eq.equals(emailOption.value, user.right.email))) &&
                isRight(url)) {
                yield put(push(url.right));
            }
            else {
                yield put(push($routes.ACTIVE_RECORDS));
            }
        }
    }
    catch (e) {
        yield* call(Context.intercom.restart(), window);
        if (e.response) {
            yield* put(Process.Failed(action)(ClientError(e, e.response)));
        }
        else {
            yield* put(Process.Failed(action)(ClientError(e)));
        }
    }
}
// Se presente un param per un redirect
// aggiorno l'url in local storage
// per utilizzarla in fase di login
export const refreshUrl = (param) => (location) => pipe(location.search, $url.searchLookup(param, BooleanQueryParameterC), either.match(constVoid, queryParamValue => {
    if (Eq.equals(queryParamValue, true)) {
        Context.cache.set('url', location.pathname)();
        Context.cache.set('forcePath', queryParamValue)();
    }
    else {
        Context.cache.removeItem('url')();
        Context.cache.removeItem('forcePath')();
    }
}));
export function getDomainCode(location) {
    const isFmp = /pratiche(\/(?!in-lavorazione\b)(?!concluse\b)[^/]+)?(\/documentazione\/?)?\/?$|^pratiche\/?$/.test(location);
    if (isFmp) {
        return 'fmp';
    }
    return 'nexus';
}
function* refreshSourceByPathName(action) {
    const domainCode = getDomainCode(action.payload.pathname);
    const { data } = yield* call(Context.http().patch, SECURITY_SESSION, {
        domainCode: domainCode,
    });
    const user = UserC.decode(data);
    if (either.isLeft(user)) {
        yield* put(Process.Failed(action)(new Error('no user found')));
        return;
    }
    yield* put(Process.Done(action)(user.right));
}
function* refreshSourceByDomainCode(action) {
    const { data } = yield* call(Context.http().patch, SECURITY_SESSION, {
        domainCode: action.payload.domainCode,
    });
    const user = UserC.decode(data);
    if (either.isLeft(user)) {
        yield* put(Process.Failed(action)(new Error('no user found')));
        return;
    }
    yield* put(Process.Done(action)(user.right));
}
function* checkSession(action) {
    try {
        refreshUrl(REDIRECT_FORCEPATH)(action.payload.location);
        const domainCode = getDomainCode(action.payload.location.pathname);
        const { data } = yield* call(Context.http().patch, SECURITY_SESSION, {
            domainCode: domainCode,
        });
        const user = UserC.decode(data);
        if (isLeft(user)) {
            throw new Error();
        }
        yield* call(Context.intercom.restart(Context.intercom.user.some(ROLES)(user.right)), window);
        yield* call(Hotjar.identifyUser, window, user.right, ROLES);
        yield* put(Process.Done(action)(user.right));
    }
    catch (e) {
        yield* call(Context.intercom.restart(), window);
        yield* put(Process.Failed(action)(e));
    }
}
export const isSessionExpired = (error) => string.Eq.equals(error, SESSION_RESPONSE_ERROR.RETRY_LOGIN);
const redirect = (state) => pipe(SecurityState.selector.auth(state), option.match(constTrue, not(isSessionExpired)));
function* cleanState() {
    const state = yield select();
    const shallRedirect = redirect(state);
    const resetFilter = {
        firstName: '',
        lastName: '',
        id: '',
        group: false,
        status: defaultStatusObject,
    };
    // recupero la mail  prima di svuotare l'intera cache
    const email = yield* call(Context.cache.get('email'));
    yield* call(Context.cache.clear());
    // aggiungo nuovamente la mail . E' una info che non voglio perdere
    if (isRight(email)) {
        yield* call(Context.cache.set('email', email.right));
    }
    yield* call(Context.intercom.restart(), window);
    yield* call(Hotjar.guestUser, window);
    yield* put(UpdateFilterRequest({
        activeRecords: Object.assign(Object.assign({}, resetFilter), { states: ALL_FILTER.STATES, agenteId: ALL_FILTER.ASSIGNEE, tipo_di_prodotto_long: ALL_FILTER.PRODUCT, assignee: defaultAssigneeObject, digital: false }),
        closedRecords: Object.assign(Object.assign({}, resetFilter), { states: ALL_FILTER.STATES }),
        url: '',
    }));
    if (shallRedirect) {
        yield put(push($routes.ACTIVE_RECORDS));
    }
    else {
        if (state.router.location) {
            yield* call(Context.cache.set('url', state.router.location.pathname));
        }
    }
}
function* logOut(action) {
    const token = yield* call(StorageCache(localStorage).get(TOKEN));
    try {
        if (isRight(token)) {
            yield call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).delete, SECURITY_SESSION);
        }
        yield call(cleanState);
        yield* call(googleLogout);
        yield* put(Process.Done(action)(action.payload));
    }
    catch (e) {
        if (e.response) {
            const { status } = e.response;
            if (status === 401) {
                yield call(cleanState);
                yield* put(Process.Done(action)(action.payload));
            }
        }
    }
}
export function* SecuritySaga() {
    yield* all([
        takeLatest(SecurityActions.Login.type.fired, logIn),
        takeLatest(SecurityActions.Session.type.fired, checkSession),
        takeLatest(SecurityActions.RefreshSourceByPathName.type.fired, refreshSourceByPathName),
        takeLatest(SecurityActions.RefreshSourceByDomainCode.type.fired, refreshSourceByDomainCode),
        takeLatest(SecurityActions.Logout.type.fired, logOut),
    ]);
}
