import { option } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { $currency } from '../../../models/Currency/Currency';
const tableHeaders = {
    clientFullName: 'Nome e cognome',
    recordId: 'ID pratica',
    brokerageAmount: 'Mediazione',
    recordState: 'Stato di lavorazione',
};
const EMPTY = '-';
const artooRecordTable = (artooRecord) => ({
    headers: tableHeaders,
    body: [
        {
            clientFullName: `${artooRecord.data.nome} ${artooRecord.data.cognome}`,
            recordId: artooRecord.id,
            brokerageAmount: pipe(artooRecord.data.mutuo_importo_di_mediazione, option.fromNullable, option.map($currency.toEuros), option.getOrElse(constant(EMPTY))),
            recordState: artooRecord.status.name,
        },
    ],
});
const paperworkTable = (paperwork) => ({
    headers: tableHeaders,
    body: [
        {
            clientFullName: `${paperwork.data.nome} ${paperwork.data.cognome}`,
            recordId: paperwork.id.slice(0, 8),
            brokerageAmount: pipe(paperwork.data.mutuo_importo_di_mediazione, option.fromNullable, option.map($currency.toEuros), option.getOrElse(constant(EMPTY))),
            recordState: paperwork.currentStatus.label,
        },
    ],
});
const paperworkAgents = (paperwork) => [
    { first_name: paperwork.createdBy.firstName, last_name: paperwork.createdBy.lastName, id: paperwork.id },
];
export const recordFetched = (state, event) => {
    const LOADING_STATUS = false;
    if (event.payload.type === 'artoo') {
        return Object.assign(Object.assign({}, state), { loading: LOADING_STATUS, table: artooRecordTable(event.payload.record), artooRecordAgents: event.payload.record.agenti, artooRecordData: event.payload.record.data });
    }
    return Object.assign(Object.assign({}, state), { loading: LOADING_STATUS, table: paperworkTable(event.payload.paperwork), artooRecordAgents: paperworkAgents(event.payload.paperwork), artooRecordData: event.payload.paperwork.data });
};
