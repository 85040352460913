import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { all, call, cancel, debounce, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { Notification } from '../../../utils/notification';
import { getPaperwork } from '../../web/getPaperwork';
import { getPayinsByRecordId } from '../../web/getPayinsByRecordId';
import { getInvoicingRecordList } from '../../web/getRecordList';
import { getUserList } from '../../web/getUserList';
import { $RecordListWithCommissions } from './RecordListWithCommissionsState';
function* fetchUserList(action) {
    yield* put($RecordListWithCommissions.UserListRequested());
    const users = yield* call(getUserList(action.payload.params));
    if (either.isLeft(users)) {
        yield* put($RecordListWithCommissions.UserListNotFetched(users.left));
    }
    else {
        yield* put($RecordListWithCommissions.UserListFetched(users.right));
    }
}
function* fetchRecordList(action) {
    try {
        if (Number(action.payload.formData.userId) > 0) {
            yield* put($RecordListWithCommissions.RecordWithCommissionsRequested());
            const recordWithCommissionList = [];
            const paperworkWithCommissionList = [];
            yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
            yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
            const recordListAction = yield* call(getInvoicingRecordList({
                assignees: [action.payload.formData.userId],
            }));
            yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'fmp' }));
            yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
            const paperworkListEither = yield* call(getPaperwork({
                page: { size: 100 },
                filter: { assigneeExternalId: action.payload.formData.userId, currentStatus: 'toBeInvoiced' },
            }));
            if (either.isLeft(recordListAction)) {
                throw recordListAction.left;
            }
            if (either.isLeft(paperworkListEither)) {
                throw paperworkListEither.left;
            }
            yield* paperworkListEither.right.map(function* (paperwork) {
                const payinsEither = yield* call(getPayinsByRecordId(paperwork.artooId || ''));
                pipe(payinsEither, either.match(() => paperworkWithCommissionList.push({
                    record: paperwork,
                    hasError: true,
                    payins: [],
                }), payins => paperworkWithCommissionList.push({
                    record: paperwork,
                    hasError: false,
                    payins: payins,
                })));
            });
            yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: 'nexus' }));
            yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
            yield* recordListAction.right.map(function* (record) {
                const payinsEither = yield* call(getPayinsByRecordId(record.id));
                pipe(payinsEither, either.match(() => recordWithCommissionList.push({
                    record: record,
                    hasError: true,
                    payins: [],
                }), payins => recordWithCommissionList.push({
                    record: record,
                    hasError: false,
                    payins: payins,
                })));
            });
            yield* put($RecordListWithCommissions.RecordWithCommissionsFetched({
                recordWithCommissions: recordWithCommissionList,
                paperworkWithCommissions: paperworkWithCommissionList,
            }));
        }
    }
    catch (e) {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($RecordListWithCommissions.RecordWithCommissionsNotFetched(e));
    }
}
export function* RecordListWithCommissionsSaga() {
    yield* takeLeading($RecordListWithCommissions.start, function* () {
        yield* put($RecordListWithCommissions.Started());
        const task = yield* all([
            yield* debounce(1000, $RecordListWithCommissions.fetchUserList, fetchUserList),
            yield* takeLatest($RecordListWithCommissions.fetchRecordWithCommissions, fetchRecordList),
        ]);
        yield* take($RecordListWithCommissions.stop);
        yield* cancel(task);
        yield* put($RecordListWithCommissions.Stopped());
    });
}
