import axios from 'axios';
export class AxiosClient {
    addBearerAuthorization(token) {
        return { Authorization: `Bearer ${token}` };
    }
    isAxiosError(error) {
        return (typeof error === 'object' &&
            error !== null &&
            'isAxiosError' in error &&
            error.isAxiosError === true);
    }
    isForbidden(error) {
        var _a;
        if (this.isAxiosError(error)) {
            return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 403;
        }
        return false;
    }
    forbiddenError(e) {
        if (this.isForbidden(e)) {
            return { isForbidden: true };
        }
        return { isForbidden: false };
    }
    constructor(url) {
        this.http = axios.create({
            baseURL: url,
            headers: { 'content-type': 'application/json' },
        });
    }
}
