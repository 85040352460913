import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
import { recordWithCommissionsFetched } from './reducers/recordWithCommissionsFetched';
const initialState = {
    loading: { recordList: false, users: false },
    error: { users: option.none, recordList: option.none },
    itemsToTransmit: [],
    transmittedItems: [],
    users: [],
    filterInitialValues: {
        userId: '',
        userFullName: '',
    },
};
export const recordListWithCommissionsSlice = createSlice({
    name: 'RecordsSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchUserList: (state, _) => state,
        fetchRecordWithCommissions: (state, _) => state,
        RecordWithCommissionsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: true }), error: Object.assign(Object.assign({}, state.error), { recordList: option.none }) })),
        RecordWithCommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: false }), error: Object.assign(Object.assign({}, state.error), { recordList: option.some(event.payload) }) })),
        RecordWithCommissionsFetched: (state, event) => recordWithCommissionsFetched(state, event),
        UserListFetched: (state, event) => (Object.assign(Object.assign({}, state), { users: event.payload, loading: Object.assign(Object.assign({}, state.loading), { users: false }), error: Object.assign(Object.assign({}, state.error), { users: option.none }) })),
        UserListNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { users: false }), users: [], error: Object.assign(Object.assign({}, state.error), { users: option.some(event.payload) }) })),
        UserListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { users: true }), error: Object.assign(Object.assign({}, state.error), { users: option.none }), users: [] })),
    },
});
const itemsToTransmit = (state) => state.records.itemsToTransmit;
const transmittedItems = (state) => state.records.transmittedItems;
const isLoading = (state) => state.records.loading.recordList;
const filterInitialValues = (state) => state.records.filterInitialValues;
const assignees = (state) => state.records.users;
const assigneeError = (state) => option.isSome(state.records.error.users);
const isUserListLoading = (state) => state.records.loading.users;
export const $RecordListWithCommissions = recordListWithCommissionsSlice.actions;
export const $recordListWithCommissionsSlice = {
    selector: {
        transmittedItems,
        itemsToTransmit,
        isLoading,
        filterInitialValues,
        assignees,
        assigneeError,
        isUserListLoading,
    },
};
export default recordListWithCommissionsSlice.reducer;
