import { SIGNATURE_STATUS_CODE } from '../../../models/SignatureStatusCodeModel';
import { documentationModelMapper } from './documentationModelMapper';
import { nextActionMapper } from './nextActionMapper';
import { productTypologyModelMapper } from './productTypologyModelMapper';
const isPartiallySigned = (paperworkDocuments) => paperworkDocuments.some(document => document.required && document.toBeSigned && document.signed);
export const paperworkModelMapper = (paperwork) => {
    const nextActionsCodes = paperwork.nextActions.map(({ code }) => code);
    return {
        productTypology: productTypologyModelMapper(paperwork.productType),
        data: paperwork.data,
        details: {
            id: paperwork.id,
            assignedTo: paperwork.assignedTo,
            createdAt: paperwork.createdAt,
            createdBy: paperwork.createdBy,
            artooId: paperwork.artooId || undefined,
            transitionsHistory: paperwork.transitionsHistory.map(transition => ({
                status: transition.status,
                triggeredAt: transition.triggeredAt,
                triggeredBy: transition.triggeredBy,
            })),
            nextActions: paperwork.nextActions.map(nextActionMapper),
            currentStatus: paperwork.currentStatus,
            documentTypes: paperwork.documentTypes,
            permissions: {
                read: paperwork.permissions.read,
                edit: paperwork.permissions.edit,
                transit: paperwork.permissions.transit && paperwork.nextActions.length > 0,
                'document.create': paperwork.permissions['document.create'],
                'document.delete': paperwork.permissions['document.delete'],
                'field.definedStipulationDate.edit': paperwork.permissions['field.definedStipulationDate.edit'] || false,
                'field.mediationAmount.edit': paperwork.permissions['field.mediationAmount.edit'] || false,
                'field.requestedAmount.edit': paperwork.permissions['field.requestedAmount.edit'] || false,
            },
            documentation: paperwork.documents.map(documentationModelMapper),
            digitalSignature: {
                isEnabled: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REQUEST_DIGITAL_SIGN),
                isRequested: paperwork.currentStatus.code === SIGNATURE_STATUS_CODE.DIGITAL_SIGN_REQUESTED,
                isRevocable: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REVOKE_DIGITAL_SIGN_REQUEST),
            },
            handwrittenSignature: {
                isEnabled: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REQUEST_HANDWRITTEN_SIGN),
                isRequested: paperwork.currentStatus.code === SIGNATURE_STATUS_CODE.HANDWRITTEN_SIGN_REQUESTED,
                isRevocable: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REVOKE_HANDWRITTEN_SIGN_REQUEST),
            },
            isPartiallySigned: isPartiallySigned(paperwork.documents),
        },
    };
};
