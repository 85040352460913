import { format } from 'date-fns';
import { option } from 'fp-ts';
import { numericFormatter } from 'react-number-format';
import { _canBeDeleted, LOAN_DETAIL_STATE_MODEL } from '../PaperworkProcessState';
import { currencyFormatterRules } from '../utils/currencyFormatterRules';
import { hasInvestigationAsSubordinateProduct } from '../utils/hasInvestigationAsSubordinateProduct';
const DATE_FORMAT = 'dd/MM/yyyy';
const formatDate = (unsavedFormDate, fallbackDate) => {
    if (unsavedFormDate) {
        return format(unsavedFormDate, DATE_FORMAT);
    }
    if (fallbackDate) {
        return format(fallbackDate, DATE_FORMAT);
    }
    return null;
};
const formatAmount = (unsavedFormCurrency, fallbackCurrency) => {
    const EURO = '€';
    if (unsavedFormCurrency) {
        return `${numericFormatter(unsavedFormCurrency.toString(), currencyFormatterRules)} ${EURO}`;
    }
    if (fallbackCurrency !== null && fallbackCurrency !== undefined) {
        return `${numericFormatter(fallbackCurrency.toString(), currencyFormatterRules)} ${EURO}`;
    }
    return null;
};
const addSuffix = (suffix) => (unsavedFormData, fallbackData) => {
    if (unsavedFormData) {
        return `${unsavedFormData}${suffix}`;
    }
    if (fallbackData) {
        return `${fallbackData}${suffix}`;
    }
    return null;
};
export const _getPaperworkIndexOption = (index, partialKey) => {
    switch (index) {
        case LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.FIRST_APPLICANT:
            return option.some(partialKey);
        case LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.SECOND_APPLICANT:
            return option.some(`intestatario_0_${partialKey}`);
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FIRST_POLICY:
            return option.some(`polizza_assicurativa_${partialKey}`);
        case LOAN_DETAIL_STATE_MODEL.POLICIES.SECOND_POLICY:
            return option.some(`polizza_assicurativa_1_${partialKey}`);
        case LOAN_DETAIL_STATE_MODEL.POLICIES.THIRD_POLICY:
            return option.some(`polizza_assicurativa_2_${partialKey}`);
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FOURTH_POLICY:
            return option.some(`polizza_assicurativa_3_${partialKey}`);
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FIFTH_POLICY:
            return option.some(`polizza_assicurativa_4_${partialKey}`);
        default:
            return option.none;
    }
};
const getBadgeTitle = (index) => {
    switch (index) {
        case LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.FIRST_APPLICANT:
            return 'Primo intestatario';
        case LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.SECOND_APPLICANT:
            return 'Secondo intestatario';
        default:
            return undefined;
    }
};
const getPolicyTitle = (index) => {
    switch (index) {
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FIRST_POLICY:
            return 'Prima polizza assicurativa';
        case LOAN_DETAIL_STATE_MODEL.POLICIES.SECOND_POLICY:
            return 'Seconda polizza assicurativa';
        case LOAN_DETAIL_STATE_MODEL.POLICIES.THIRD_POLICY:
            return 'Terza polizza assicurativa';
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FOURTH_POLICY:
            return 'Quarta polizza assicurativa';
        case LOAN_DETAIL_STATE_MODEL.POLICIES.FIFTH_POLICY:
            return 'Quinta polizza assicurativa';
        default:
            return undefined;
    }
};
const loanDatumByKey = (loanData, participantIndex) => (partialKey) => {
    const participantIndexOption = _getPaperworkIndexOption(participantIndex, partialKey);
    if (option.isNone(participantIndexOption) || !loanData) {
        return null;
    }
    return loanData[participantIndexOption.value];
};
export const _participantSection = (unsavedForm, loanData, participantIndex) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    const unsavedParticipantForm = (unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.participants) &&
        unsavedForm.participants.find(participant => participant.index === participantIndex);
    const getLoanValueByKey = loanDatumByKey(loanData, participantIndex);
    if (!(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.firstName) && !getLoanValueByKey('nome')) {
        return [];
    }
    const badgeTitle = getBadgeTitle(participantIndex);
    return [
        Object.assign(Object.assign({}, (badgeTitle && { badgeTitle: badgeTitle })), { title: `${(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.firstName) || getLoanValueByKey('nome')} ${(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.lastName) || getLoanValueByKey('cognome')}`, rows: {
                'Indirizzo di residenza': (_a = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.residence.address) || getLoanValueByKey('indirizzo_di_residenza'))) !== null && _a !== void 0 ? _a : null,
                'Località di residenza': (_b = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.residence.locationInput) || getLoanValueByKey('localita_di_residenza'))) !== null && _b !== void 0 ? _b : null,
                'CAP di residenza': (_c = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.residence.postalCode) || getLoanValueByKey('cap_di_residenza'))) !== null && _c !== void 0 ? _c : null,
                'Comune di nascita': (_d = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.cityOfBirth) || getLoanValueByKey('comune_di_nascita'))) !== null && _d !== void 0 ? _d : null,
                'Provincia di nascita': (_e = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.provinceOfBirth) || getLoanValueByKey('provincia_di_nascita'))) !== null && _e !== void 0 ? _e : null,
                'Data di nascita': formatDate(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.birthday, (_f = getLoanValueByKey('data_di_nascita')) === null || _f === void 0 ? void 0 : _f.toString()),
                Sesso: (_g = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.gender) || getLoanValueByKey('sesso'))) !== null && _g !== void 0 ? _g : '',
                'Codice fiscale': (_h = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.fiscalCode) || getLoanValueByKey('codice_fiscale'))) !== null && _h !== void 0 ? _h : null,
                'Tipo di documento': (_j = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.type) || getLoanValueByKey('tipo_di_documento'))) !== null && _j !== void 0 ? _j : null,
                Cittadinanza: (_k = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.citizenship) || getLoanValueByKey('cittadinanza'))) !== null && _k !== void 0 ? _k : null,
                'N° di documento': (_l = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.code) || getLoanValueByKey('n_di_documento'))) !== null && _l !== void 0 ? _l : null,
                'Rilasciato da': (_m = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.issuedBy) || getLoanValueByKey('rilasciato_da'))) !== null && _m !== void 0 ? _m : null,
                'Luogo di rilascio': (_o = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.placeOfIssue) || getLoanValueByKey('luogo_di_rilascio'))) !== null && _o !== void 0 ? _o : null,
                'Data di rilascio': formatDate(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.issueDate, (_p = getLoanValueByKey('data_di_rilascio')) === null || _p === void 0 ? void 0 : _p.toString()),
                'Data di scadenza': formatDate(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.identityDocument.expiryDate, (_q = getLoanValueByKey('data_di_scadenza')) === null || _q === void 0 ? void 0 : _q.toString()),
            } }),
        {
            title: 'Contatti',
            rows: {
                'Telefono cellulare': (_r = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.contacts.mobilePhone) || getLoanValueByKey('telefono_cellulare'))) !== null && _r !== void 0 ? _r : null,
                'Telefono fisso': (_s = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.contacts.landline) || getLoanValueByKey('telefono_fisso'))) !== null && _s !== void 0 ? _s : null,
                Email: (_t = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.contacts.email) || getLoanValueByKey('email'))) !== null && _t !== void 0 ? _t : null,
            },
        },
        {
            title: 'Dati reddituali',
            rows: {
                'Stato civile': (_u = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.maritalStatus) || getLoanValueByKey('stato_civile'))) !== null && _u !== void 0 ? _u : null,
                Professione: (_v = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.job) || getLoanValueByKey('professione'))) !== null && _v !== void 0 ? _v : null,
                'Tipo di azienda': (_w = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.companyType) || getLoanValueByKey('tipo_di_azienda'))) !== null && _w !== void 0 ? _w : null,
                'P.IVA datore di lavoro': (_x = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.employerVatNumber) || getLoanValueByKey('partita_iva_datore_di_lavoro'))) !== null && _x !== void 0 ? _x : null,
                'Reddito mensile netto': formatAmount(unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.netIncome, getLoanValueByKey('reddito_mensile_netto')),
                'Numero di mensilità': (_z = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.monthlyInstallment) ||
                    ((_y = getLoanValueByKey('numero_di_mensilita')) === null || _y === void 0 ? void 0 : _y.toString()))) !== null && _z !== void 0 ? _z : null,
                'Anzianità lavorativa': (unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.lengthOfService) || getLoanValueByKey('anzianita_lavorativa')
                    ? `${getLoanValueByKey('anzianita_lavorativa')} mesi`
                    : null,
                'Componenti nucleo familiare': (_1 = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.household) ||
                    ((_0 = getLoanValueByKey('componenti_nucleo_familiare')) === null || _0 === void 0 ? void 0 : _0.toString()))) !== null && _1 !== void 0 ? _1 : null,
            },
        },
        {
            title: 'Consensi',
            rows: {
                "Consenso per l'invio di comunicazioni promozionali e di marketing": (_2 = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.marketing) ||
                    getLoanValueByKey('consenso_invio_comunicazioni_promozionali_e_marketing'))) !== null && _2 !== void 0 ? _2 : false,
                'Consenso per la cessione di dati a terzi': (_3 = ((unsavedParticipantForm === null || unsavedParticipantForm === void 0 ? void 0 : unsavedParticipantForm.thirdParties) || getLoanValueByKey('consenso_cessione_dati_a_terzi'))) !== null && _3 !== void 0 ? _3 : false,
            },
        },
    ];
};
export const _policySection = (unsavedForm, loanData, policyIndex) => {
    const unsavedPolicyForm = (unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.policies) && unsavedForm.policies.find(policy => policy.index === policyIndex);
    const getLoanValueByKey = loanDatumByKey(loanData, policyIndex);
    const type = (unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.type) || getLoanValueByKey('tipo_di_polizza');
    if (!(unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.type) && !type) {
        return [];
    }
    const institute = (unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.institute) || getLoanValueByKey('compagnia_assicurativa');
    const product = (unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.product) || getLoanValueByKey('prodotto');
    const amount = formatAmount(unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.amount, getLoanValueByKey('valore_della_polizza'));
    const premiumType = (unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.premiumType) || getLoanValueByKey('tipo_di_premio');
    const brokerageAmount = formatAmount(unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.brokerageAmount, getLoanValueByKey('importo_di_brokeraggio'));
    const typeOfCoverage = (unsavedPolicyForm === null || unsavedPolicyForm === void 0 ? void 0 : unsavedPolicyForm.typeOfCoverage) || getLoanValueByKey('tipo_di_copertura');
    const title = getPolicyTitle(policyIndex);
    const POLARIS_POLICY = 'Polizza Polaris';
    return [
        {
            title: title === undefined ? '' : title,
            rows: Object.assign(Object.assign(Object.assign({ 'Tipo di polizza': type || null }, (type === POLARIS_POLICY && {
                'Compagnia assicurativa': institute || null,
            })), { Prodotto: product || null, 'Tipologia di copertura': typeOfCoverage || null, 'Importo della polizza': amount || null, 'Tipo di premio': premiumType || null }), (type === POLARIS_POLICY && { 'Importo di brokeraggio': brokerageAmount || null })),
        },
    ];
};
const isAgosInstitute = (unsavedInstitute, fallbackInstitute) => {
    const AGOS = 'Agos';
    if (unsavedInstitute === AGOS) {
        return true;
    }
    if (!unsavedInstitute && fallbackInstitute === AGOS) {
        return true;
    }
    return false;
};
const isCofidisInstitute = (unsavedInstitute, fallbackInstitute) => {
    const COFIDIS = 'Cofidis';
    if (unsavedInstitute === COFIDIS) {
        return true;
    }
    if (!unsavedInstitute && fallbackInstitute === COFIDIS) {
        return true;
    }
    return false;
};
const hasCpiList = (institute, product) => {
    const COMPASS = 'Compass';
    if (product.unsaved && institute.unsaved === COMPASS) {
        return product.unsaved;
    }
    if (product.fallback && institute.fallback === COMPASS) {
        return product.fallback;
    }
    return false;
};
const hasCpi = (unsavedCpi, fallbackCpi) => {
    if (unsavedCpi === true) {
        return true;
    }
    if ((unsavedCpi === null || unsavedCpi === undefined) && fallbackCpi === true) {
        return true;
    }
    return false;
};
const hasInvestigation = (products, unsavedProduct, fallbackProduct) => {
    if (unsavedProduct) {
        return hasInvestigationAsSubordinateProduct(unsavedProduct, products);
    }
    if (!unsavedProduct && fallbackProduct) {
        return hasInvestigationAsSubordinateProduct(fallbackProduct, products);
    }
    return false;
};
export const loanDetailStateModelMapper = (unsavedForm, loanData, permissions, drafts, products = []) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    const firstParticipant = _participantSection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.FIRST_APPLICANT);
    const secondParticipant = _participantSection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.SECOND_APPLICANT);
    const firstPolicy = _policySection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.POLICIES.FIRST_POLICY);
    const secondPolicy = _policySection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.POLICIES.SECOND_POLICY);
    const thirdPolicy = _policySection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.POLICIES.THIRD_POLICY);
    const fourthPolicy = _policySection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.POLICIES.FOURTH_POLICY);
    const fifthPolicy = _policySection(unsavedForm, loanData, LOAN_DETAIL_STATE_MODEL.POLICIES.FIFTH_POLICY);
    return [
        {
            title: 'Dati operativi',
            id: LOAN_DETAIL_STATE_MODEL.OPERATIONAL_DATA,
            section: [
                {
                    title: 'Store di riferimento',
                    rows: { Store: ((_a = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _a === void 0 ? void 0 : _a.store) || (loanData === null || loanData === void 0 ? void 0 : loanData.store) || null },
                },
                {
                    title: 'Rubinetto',
                    hidden: !permissions.canViewChannel,
                    rows: {
                        Rubinetto: (_c = (((_b = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _b === void 0 ? void 0 : _b.channel) || (loanData === null || loanData === void 0 ? void 0 : loanData.rubinetto))) !== null && _c !== void 0 ? _c : null,
                    },
                },
                {
                    title: 'Mandato',
                    rows: {
                        'Data firma mandato': formatDate((_d = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _d === void 0 ? void 0 : _d.warrantStartDate, loanData === null || loanData === void 0 ? void 0 : loanData.data_firma_mandato),
                        'Data scadenza mandato': formatDate((_e = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _e === void 0 ? void 0 : _e.warrantEndDate, loanData === null || loanData === void 0 ? void 0 : loanData.data_scadenza_mandato),
                    },
                },
                {
                    title: 'Finanziamento',
                    rows: {
                        'Data erogazione finanziamento': formatDate((_f = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _f === void 0 ? void 0 : _f.expectedLendingDate, loanData === null || loanData === void 0 ? void 0 : loanData.data_erogazione_finanziamento),
                        'Mezzo di pagamento finanziamento': (_h = (((_g = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.operationalData) === null || _g === void 0 ? void 0 : _g.lendingPaymentMethod) ||
                            (loanData === null || loanData === void 0 ? void 0 : loanData.mezzo_di_pagamento_finanziamento))) !== null && _h !== void 0 ? _h : null,
                    },
                },
            ],
        },
        {
            id: LOAN_DETAIL_STATE_MODEL.LOAN,
            section: [
                {
                    title: 'Prestito',
                    rows: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ Finalità: (_k = ((_j = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _j === void 0 ? void 0 : _j.purpose, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_finalita)) !== null && _k !== void 0 ? _k : null, Finanziaria: (_m = ((_l = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _l === void 0 ? void 0 : _l.institute, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_finanziaria)) !== null && _m !== void 0 ? _m : null }, (products.length > 0 && {
                        Prodotto: (_p = (((_o = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _o === void 0 ? void 0 : _o.product) || (loanData === null || loanData === void 0 ? void 0 : loanData.prestito_prodotto))) !== null && _p !== void 0 ? _p : null,
                    })), { 'Importo richiesto': formatAmount((_q = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _q === void 0 ? void 0 : _q.requestedAmount, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_importo_richiesto), Durata: (_t = (((_r = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _r === void 0 ? void 0 : _r.duration) || ((_s = loanData === null || loanData === void 0 ? void 0 : loanData.prestito_durata_mesi) === null || _s === void 0 ? void 0 : _s.toString()))) !== null && _t !== void 0 ? _t : null }), (isAgosInstitute((_u = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _u === void 0 ? void 0 : _u.institute, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_finanziaria) && {
                        'Tipo di cliente': (_w = (((_v = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _v === void 0 ? void 0 : _v.customerType) || (loanData === null || loanData === void 0 ? void 0 : loanData.prestito_tipo_di_cliente))) !== null && _w !== void 0 ? _w : null,
                    })), { TAN: (_y = addSuffix('%')(((_x = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _x === void 0 ? void 0 : _x.tan.toString()) || (loanData === null || loanData === void 0 ? void 0 : loanData.prestito_taeg))) !== null && _y !== void 0 ? _y : null }), (hasInvestigation(products, (_z = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _z === void 0 ? void 0 : _z.product, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_prodotto) && {
                        'Spese istruttoria': formatAmount((_0 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _0 === void 0 ? void 0 : _0.processingFee, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_taeg),
                    })), { Rata: formatAmount((_1 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _1 === void 0 ? void 0 : _1.installment, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_rata) }), (hasCpiList({
                        unsaved: (_2 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _2 === void 0 ? void 0 : _2.institute,
                        fallback: loanData === null || loanData === void 0 ? void 0 : loanData.prestito_finanziaria,
                    }, {
                        unsaved: (_3 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _3 === void 0 ? void 0 : _3.product,
                        fallback: loanData === null || loanData === void 0 ? void 0 : loanData.prestito_prodotto,
                    }) && {
                        CPI: (_5 = (((_4 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _4 === void 0 ? void 0 : _4.cpiList.join(', ')) || (loanData === null || loanData === void 0 ? void 0 : loanData.prestito_cpi))) !== null && _5 !== void 0 ? _5 : null,
                    })), (isCofidisInstitute((_6 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _6 === void 0 ? void 0 : _6.institute, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_finanziaria) && {
                        CPI: (_8 = (((_7 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _7 === void 0 ? void 0 : _7.hasCpi) || (loanData === null || loanData === void 0 ? void 0 : loanData.prestito_cpi_cofidis))) !== null && _8 !== void 0 ? _8 : false,
                    })), (hasCpi((_9 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _9 === void 0 ? void 0 : _9.hasCpi, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_cpi_cofidis) && {
                        'Importo polizza': formatAmount((_10 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _10 === void 0 ? void 0 : _10.policyRequestedAmount, loanData === null || loanData === void 0 ? void 0 : loanData.prestito_cpi_cofidis_importo_richiesto),
                    })), { Note: (_12 = (((_11 = unsavedForm === null || unsavedForm === void 0 ? void 0 : unsavedForm.loan) === null || _11 === void 0 ? void 0 : _11.notes) || (loanData === null || loanData === void 0 ? void 0 : loanData.note))) !== null && _12 !== void 0 ? _12 : null }),
                },
            ],
        },
        {
            canBeDeleted: false,
            hidden: firstParticipant.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.FIRST_APPLICANT,
            section: firstParticipant,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: secondParticipant.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.PARTICIPANTS.SECOND_APPLICANT,
            section: secondParticipant,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: firstPolicy.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.POLICIES.FIRST_POLICY,
            section: firstPolicy,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: secondPolicy.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.POLICIES.SECOND_POLICY,
            section: secondPolicy,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: thirdPolicy.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.POLICIES.THIRD_POLICY,
            section: thirdPolicy,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: fourthPolicy.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.POLICIES.FOURTH_POLICY,
            section: fourthPolicy,
        },
        {
            canBeDeleted: _canBeDeleted(drafts),
            hidden: fifthPolicy.length === 0,
            id: LOAN_DETAIL_STATE_MODEL.POLICIES.FIFTH_POLICY,
            section: fifthPolicy,
        },
    ];
};
