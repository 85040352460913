import { array, option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $date } from '../../../models/Date';
import { RecordDataInstituteUtils } from '../../../models/RecordDataInstitute';
const getInstitute = (paperworkData) => {
    switch (paperworkData.tipo_di_prodotto_long) {
        case 'Mutuo':
            return paperworkData.mutuo_istituto;
        case 'Prestito':
            return paperworkData.prestito_finanziaria;
        case 'Cessione del quinto':
            return paperworkData.cessione_del_quinto_finanziaria;
        case 'Anticipo TFS':
            return paperworkData.anticipo_tfs_finanziaria;
        case 'Polizza assicurativa':
            return paperworkData.polizza_assicurativa_compagnia_assicurativa;
        default:
            return undefined;
    }
};
const userFullName = (record) => pipe(record.data, readonlyRecord.lookup('nome_e_cognome'), option.filter(t.string.is), option.getOrElse(constant('')));
const emittedDate = (expectedLendingDate) => pipe(expectedLendingDate, option.fromNullable, option.chain($date.dayMonthYear), option.getOrElse(constant('')));
export const recordListFetched = (state, event) => {
    const bazaarTable = pipe(event.payload.bazaarRecord, array.map(paperwork => ({
        paperworkId: paperwork.id.slice(0, 8),
        artooId: paperwork.artooId || '',
        userFullName: `${paperwork.data.nome} ${paperwork.data.cognome}`,
        productType: paperwork.data.tipo_di_prodotto || '',
        institute: getInstitute(paperwork.data) || '',
        emittedDate: emittedDate(paperwork.data.data_erogazione_finanziamento),
    })));
    const artooTable = pipe(event.payload.artooRecord, readonlyArray.toArray, array.map(record => ({
        paperworkId: null,
        artooId: record.id,
        userFullName: userFullName(record),
        productType: record.data.tipo_di_prodotto,
        institute: RecordDataInstituteUtils.institute(record),
        emittedDate: emittedDate(record.data.data_erogazione_finanziamento),
    })));
    return Object.assign(Object.assign({}, state), { loading: false, error: option.none, table: pipe([...bazaarTable, ...artooTable], tableBody => ({
            body: tableBody,
            headers: [
                {
                    id: 'ID pratica',
                    userFullName: 'Cliente',
                    productType: 'Prodotto',
                    institute: 'Istituto',
                    emittedDate: 'Data erogazione',
                },
            ],
        })) });
};
