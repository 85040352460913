import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { PaperworkCodec } from '../models/Paperwork';
export const getPaperwork = (queryParams = {}) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/paperwork?${qs.stringify(Object.assign({}, queryParams))}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ items: t.array(PaperworkCodec) }).decode)), taskEither.map(({ items }) => items));
