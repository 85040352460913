import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { SecurityActions } from '../../../store/security/SecurityActions';
import { Notification } from '../../../utils/notification';
import { $intl } from '../../models/Intl';
import { InvoiceStatusCodeUtils } from '../../models/InvoiceStatusCode/InvoiceStatusCode';
import { createInvoiceFromRecord } from '../../web/createInvoiceFromRecord';
import { getInvoiceList } from '../../web/getInvoiceList';
import { $InvoiceCreationFromRecord } from './InvoiceCreationFromRecordState';
function* createInvoice(recordId, domainCode, createInvoiceCommand) {
    yield* put($InvoiceCreationFromRecord.InvoiceCreationRequested());
    yield* put(SecurityActions.RefreshSourceByDomainCode({ domainCode: domainCode }));
    yield* take(SecurityActions.RefreshSourceByDomainCode.type.done);
    const createInvoiceAction = yield* call(createInvoiceFromRecord(recordId));
    if (either.isRight(createInvoiceAction)) {
        yield* put($InvoiceCreationFromRecord.InvoiceCreated());
        yield* put(NotificationAction.Push(Notification.info(`${$intl.fromGenre(createInvoiceAction.right, 'È stata correttamente creata', 'Sono state correttamente create')} ${createInvoiceAction.right} ${$intl.fromGenre(createInvoiceAction.right, 'fattura', 'fatture')}`)));
        if (createInvoiceCommand.payload.onSuccess) {
            yield* call(createInvoiceCommand.payload.onSuccess);
        }
    }
    else {
        yield* put($InvoiceCreationFromRecord.InvoiceNotCreated());
    }
}
function* fetchInvoice(recordId) {
    yield* put($InvoiceCreationFromRecord.InvoiceRequested());
    const invoiceListEither = yield* call(getInvoiceList({
        statusCode: [InvoiceStatusCodeUtils.DRAFT, InvoiceStatusCodeUtils.READY],
        'items.recordId': recordId,
    }));
    if (either.isLeft(invoiceListEither)) {
        yield* put($InvoiceCreationFromRecord.InvoiceNotFetched());
    }
    else {
        yield* put($InvoiceCreationFromRecord.InvoiceFetched(invoiceListEither.right));
    }
}
export function* InvoiceCreationFromRecordSaga() {
    yield* takeLeading($InvoiceCreationFromRecord.start, function* (startCommand) {
        yield* put($InvoiceCreationFromRecord.Started());
        const task = yield* all([
            yield* takeLatest($InvoiceCreationFromRecord.createInvoice, createInvoiceCommand => createInvoice(startCommand.payload.recordId, startCommand.payload.domainCode, createInvoiceCommand)),
            yield* takeLatest($InvoiceCreationFromRecord.fetchInvoice, () => fetchInvoice(startCommand.payload.recordId)),
        ]);
        yield* put($InvoiceCreationFromRecord.fetchInvoice());
        yield* take($InvoiceCreationFromRecord.stop);
        yield* cancel(task);
        yield* put($InvoiceCreationFromRecord.Stopped());
    });
}
