import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LogoutIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ROLES } from '../../constants';
import { session } from '../../utils/session';
import { LogoutModal } from '../LogoutModal/LogoutModalComponent';
import { Logo } from './Logo/LogoComponent';
import { useStyles } from './Topbar.styles';
const maybeUser = (user) => (user._tag === 'None' ? null : user.value);
export const Topbar = ({ logout, setOpen, user }) => {
    const { classes } = useStyles();
    const [toggleModal, setToggleModal] = useState(false);
    const toggle = () => setToggleModal(!toggleModal);
    const userRole = user._tag === 'None' ? null : session.matchRole(ROLES)(user.value);
    const onConfirmGoogleLogoutAction = () => {
        logout();
        toggle();
    };
    const u = maybeUser(user);
    return (_jsx(AppBar, { position: "sticky", classes: {
            root: clsx(classes.height60, classes.backgroundPrimaryDark, classes.boxShadowNone),
        }, children: u && (_jsx(Toolbar, { classes: {
                root: clsx(classes.mlr2, classes.heightAll, classes.height60),
            }, children: _jsxs(Grid, { container: true, className: classes.container, children: [_jsx(Grid, { item: true, xs: 2, children: _jsx(IconButton, { color: "inherit", onClick: () => setOpen(previousState => !previousState), edge: "start", size: "large", children: _jsx(MenuIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(Logo, {}) }), _jsx(Grid, { item: true, xs: 3, children: _jsxs(Grid, { container: true, item: true, wrap: "nowrap", className: clsx(classes.alignItemsCenter, classes.justifyContentEnd), children: [_jsxs(Grid, { children: [_jsxs(Typography, { variant: "h5", children: [u.first_name, " ", u.last_name] }), _jsx(Typography, { variant: "subtitle1", children: userRole })] }), _jsxs(Grid, { className: classes.ml20px, children: [_jsx(LogoutIcon, { style: { cursor: 'pointer' }, fontSize: "large", onClick: () => {
                                                setToggleModal(previousState => !previousState);
                                            } }), _jsx(LogoutModal, { onConfirmAction: onConfirmGoogleLogoutAction, onCancelAction: toggle, isOpen: toggleModal })] })] }) })] }) })) }));
};
