import { array, either, eq, ord, string, taskEither } from 'fp-ts';
import { identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { AxiosClient } from '../../../common/clients/AxiosClient';
import { Session } from '../../../session/state/Session';
import { DeleteLoanSectionMapper } from './mappers/DeleteLoanSectionMapper';
import { DeleteMortgageSectionMapper } from './mappers/DeleteMortgageSectionMapper';
import { UpdateCqsPaperworkMapper } from './mappers/UpdateCqsPaperworkMapper';
import { UpdateLoanPaperworkMapper } from './mappers/UpdateLoanPaperworkMapper';
import { UpdateMortgagePaperworkMapper } from './mappers/UpdateMortgagePaperworkMapper';
import { UpdatePaperworkFieldsMapper } from './mappers/UpdatePaperworkFieldsMapper';
import { UpdateStandalonePaperworkMapper } from './mappers/UpdateStandalonePolicyPaperworkMapper';
import { commissionItemModelMapper } from './mappers/commissionItemModelMapper';
import { documentationModelMapper } from './mappers/documentationModelMapper';
import { institutesModelMapper } from './mappers/institutesModelMapper';
import { localityModelMapper } from './mappers/localityModelMapper';
import { paperworkModelMapper } from './mappers/paperworkModelMapper';
import { paperworkSummaryModelMapper } from './mappers/paperworkSummaryModelMapper';
import { policyTypologyMapper } from './mappers/policyTypologyMapper';
import { productModelMapper } from './mappers/productModelMapper';
import { productTypologyMapper } from './mappers/productTypologyMapper';
import { signerModelMapper } from './mappers/signerModelMapper';
import { storeModelMapper } from './mappers/storeModelMapper';
import { channel } from './models/Channel';
import { commissionItem } from './models/CommissionItem';
import { documentation } from './models/Documentation';
import { institute } from './models/Institute';
import { locality } from './models/Locality';
import { paperwork } from './models/Paperwork';
import { paperworkSummary } from './models/PaperworkSummary';
import { product } from './models/Product';
import { signer } from './models/Signer';
import { store } from './models/Store';
export class BazaarClient extends AxiosClient {
    paginate(paginationDto) {
        return { page: { size: paginationDto.perPage, offset: paginationDto.skip } };
    }
    sanitizeFilter(filter) {
        const filterWithValidValue = {};
        for (const [filterStateKey, filterStateValue] of Object.entries(filter)) {
            if (typeof filterStateValue === 'string' && filterStateValue !== '') {
                filterWithValidValue[filterStateKey] = filterStateValue;
            }
        }
        return { filter: Object.assign({}, filterWithValidValue) };
    }
    isHttpError(e) {
        return typeof e === 'object' && e !== null && 'response' in e;
    }
    uploadFile({ formData, paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`api/v1/paperwork/${paperworkId}/documents`, formData, {
            headers: Object.assign(Object.assign({}, this.addBearerAuthorization(token)), { 'Content-type': 'multipart/form-data' }),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(documentation.decode), taskEither.map(documentationModelMapper));
    }
    signDocument({ formData, paperworkId, documentTypeId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`api/v1/paperwork/${paperworkId}/document-types/${documentTypeId}/sign`, formData, {
            headers: Object.assign(Object.assign({}, this.addBearerAuthorization(token)), { 'Content-type': 'multipart/form-data' }),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(documentation.decode), taskEither.map(documentationModelMapper));
    }
    deleteSection({ paperworkId, dataModel, indexToDelete, productTypology }) {
        const token = Session.getToken();
        let payload = null;
        switch (productTypology) {
            case 'mortgage':
                payload = DeleteMortgageSectionMapper.map(indexToDelete, dataModel);
                break;
            case 'loan':
                payload = DeleteLoanSectionMapper.map(indexToDelete, dataModel);
                break;
        }
        return pipe(taskEither.tryCatch(() => this.http.patch(`/api/v1/paperwork/${paperworkId}`, { data: Object.assign({}, payload) }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    updatePaperwork({ formData, paperworkId, dataModel }) {
        const token = Session.getToken();
        const payload = UpdatePaperworkFieldsMapper.map(dataModel, formData);
        return pipe(taskEither.tryCatch(() => this.http.patch(`/api/v1/paperwork/${paperworkId}`, { data: payload }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    updatePaperworkByProductTypology({ formData, paperworkId, dataModel, productTypology, }) {
        const token = Session.getToken();
        let payload = null;
        switch (productTypology) {
            case 'cqs':
                payload = UpdateCqsPaperworkMapper.map(dataModel, formData);
                break;
            case 'mortgage':
                payload = UpdateMortgagePaperworkMapper.map(dataModel, formData);
                break;
            case 'loan':
                payload = UpdateLoanPaperworkMapper.map(dataModel, formData);
                break;
            case 'policy':
                payload = UpdateStandalonePaperworkMapper.map(dataModel, formData);
                break;
        }
        if (payload === null) {
            return taskEither.left(new Error('invalid payload provided'));
        }
        return pipe(taskEither.tryCatch(() => this.http.patch(`/api/v1/paperwork/${paperworkId}`, { data: Object.assign({}, payload) }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    compilePaperwork({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`/api/v1/paperwork/${paperworkId}/compile`, undefined, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getPaperwork({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${paperworkId}`, {
            headers: this.addBearerAuthorization(token),
        }), e => this.forbiddenError(e)), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getChannels() {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/channels`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(channel).decode));
    }
    getProducts(getProductsDto) {
        const token = Session.getToken();
        const policyType = policyTypologyMapper(getProductsDto.policyType);
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/products`, {
            params: Object.assign(Object.assign({ rubinetto: getProductsDto.channel, institute: getProductsDto.institute, productTypology: productTypologyMapper(getProductsDto.productTypology), recordId: getProductsDto.artooId }, (getProductsDto.purpose && { purpose: getProductsDto.purpose })), (policyType !== null && { policyType: policyType })),
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(u => pipe(t.array(product).decode(u), either.map(array.filter(({ name }) => name !== 'none')), either.map(array.uniq(pipe(string.Eq, eq.contramap((duplicatedProduct) => duplicatedProduct.name)))), either.map(array.sort(pipe(string.Ord, ord.contramap((productToSort) => productToSort.name)))))), taskEither.map(array.map(productModelMapper)));
    }
    getInstitutes(getInstitutesDto) {
        const token = Session.getToken();
        const policyType = policyTypologyMapper(getInstitutesDto.policyType);
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/institutes`, {
            params: Object.assign(Object.assign({ productTypology: productTypologyMapper(getInstitutesDto.productTypology), recordId: getInstitutesDto.artooId, rubinetto: getInstitutesDto.channel }, (policyType !== null && { policyType: policyType })), (getInstitutesDto.artooId && { recordId: getInstitutesDto.artooId })),
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(institute).decode), taskEither.map(institutesModelMapper(getInstitutesDto.productTypology)));
    }
    getPurposes(getPurposesDto) {
        const token = Session.getToken();
        const productTypology = /^NON CONVENZIONE/.test(getPurposesDto.institute)
            ? null
            : productTypologyMapper(getPurposesDto.productTypology);
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/purposes`, {
            params: Object.assign(Object.assign({}, (productTypology !== null && { productTypology: productTypology })), { recordId: getPurposesDto.artooId, rubinetto: getPurposesDto.channel, institute: /^NON CONVENZIONE/.test(getPurposesDto.institute)
                    ? 'Banche non in convenzione'
                    : getPurposesDto.institute }),
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(t.string).decode));
    }
    getStores(storeName) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/stores`, {
            headers: this.addBearerAuthorization(token),
            params: {
                name: storeName,
                sort: 'name',
            },
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(store).decode), taskEither.map(array.map(storeModelMapper)));
    }
    getLocalities(getLocalitiesDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/localities`, {
            params: {
                search: getLocalitiesDto.search,
                'filter[type]': 'city',
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(locality).decode), taskEither.map(array.map(localityModelMapper)));
    }
    getCommissions(getCommissionsDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${getCommissionsDto.paperworkId}/commissions`, {
            headers: this.addBearerAuthorization(token),
        }), e => {
            if (this.isHttpError(e)) {
                return new Error('Non è stato possibile calcolare le provvigioni, controlla i dati inseriti.');
            }
            return new Error('Si è verificato un errore');
        }), taskEither.chain(response => pipe(response.data, t.array(t.array(commissionItem)).decode, either.map(array.map(array.map(commissionItemModelMapper))), taskEither.fromEither, taskEither.mapLeft(() => {
            return new Error('Si è verificato un errore');
        }))));
    }
    processPaperwork(transitDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`/api/v1/paperwork/${transitDto.paperworkId}/transit`, { context: null, action: transitDto.action }, {
            headers: this.addBearerAuthorization(token),
        }), e => this.forbiddenError(e)), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getSigners({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${paperworkId}/signers`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(signer).decode), taskEither.map(signers => pipe(signers, array.map(signerModelMapper))));
    }
    getPaperworkList({ pagination, filter, sort }) {
        const page = this.paginate(pagination);
        const filterQueryParams = filter ? this.sanitizeFilter(filter) : {};
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork?${qs.stringify(Object.assign(Object.assign(Object.assign({}, page), { sort }), filterQueryParams))}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperworkSummary.decode), taskEither.map(paperworkSummaryModelMapper));
    }
    deleteFile(deleteFileDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.delete(`/api/v1/paperwork/${deleteFileDto.paperworkId}/documents/${deleteFileDto.documentId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
    downloadFile({ documentId, paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`api/v1/paperwork/${paperworkId}/documents/${documentId}/file`, {
            headers: Object.assign({}, this.addBearerAuthorization(token)),
            responseType: 'blob',
        }), identity), taskEither.map(response => response.data));
    }
}
