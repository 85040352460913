export class PaginationDto {
    constructor(_perPage, _skip = 0) {
        this._perPage = _perPage;
        this._skip = _skip;
    }
    get perPage() {
        return this._perPage;
    }
    get skip() {
        return this._skip;
    }
    currentPage() {
        return this._skip / this._perPage + 1;
    }
    nextPage() {
        this._skip += this._perPage;
        return this.currentPage();
    }
    previousPage() {
        if (this._skip - this._perPage >= 0) {
            this._skip -= this._perPage;
        }
        return this.currentPage();
    }
    firstPage() {
        this._skip = 0;
        return this.currentPage();
    }
    canGoToPreviousPage() {
        return this._skip > 0;
    }
    canGoToNextPage(itemsPerPage, totalItems) {
        if (totalItems) {
            return this._skip + itemsPerPage < totalItems;
        }
        return itemsPerPage >= this._perPage;
    }
}
