export const TOKEN = 'session.token';
export const DEFAULT_PAGE_SIZE = 10;
export const FILTER = {
    RECORDS: 'records',
};
export const FILTER_STATUS = {
    EROGATA: 'erogata',
    DECLINATA: 'declinata',
    RITIRATA: 'ritirata_dal_cliente',
    MANCANZA_REQUISITI: 'mancanza_requisiti',
    ERRORE_CARICAMENTO: 'errore_di_caricamento',
    SEGNALATO_WIF: 'segnalato_wif',
    ARCHIVIATO: 'archiviata_per_decorrenza_termini',
};
export const CLOSED_RECORDS_FILTER = {
    STATES_EROGATE: 'Erogate',
    STATES_DECLINATE: 'Declinate',
    STATES_RITIRATE: 'Ritirate dal cliente',
};
export const ALL_FILTER = {
    STATES: 'Tutti gli stati',
    ASSIGNEE: 'Tutti gli assegnatari',
    PRODUCT: 'Tutti i prodotti',
    RECORDS: 'Tutte le pratiche',
};
export const ROLES = {
    ROLE_NEXUS_ADMIN: 'Amministratore di sistema',
    ROLE_NEXUS_BERGAMO_AMMINISTRATORE: 'Amministratore di Business',
    ROLE_NEXUS_BERGAMO_OPERATORE: 'Operatore Bergamo',
    ROLE_NEXUS_TIRANA_SUPERVISORE: 'Supervisore Tirana',
    ROLE_NEXUS_TIRANA_OPERATORE: 'Operatore Tirana',
    ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL: 'Head Divisional',
    ROLE_NEXUS_AGENTE_HEAD_AGENCY: 'Head Agency',
    ROLE_NEXUS_AGENTE_COLLABORATORE: 'Collaboratore',
    ROLE_NEXUS_LEGAL_OPERATORE: 'Operatore Legal',
};
export const HOTJAR = {
    PRATICA_DOCUMENTAZIONE: 'pratica_documentazione',
};
export const MODAL_OPEN_CLASS = 'body--modal-open';
