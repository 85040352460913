import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Form, Formik } from 'formik';
import { boolean, readonlyArray } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { useEffect, useState } from 'react';
import { Authorization } from '../../authorization/Authorization';
import { toggle } from '../../common/state/toggle';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PERMISSION } from '../../store/security/SecurityState';
import { CommissionsForm } from '../state/commissionsForm/CommissionsForm';
import { $RecordCommissions, $RecordCommissionsSlice } from '../state/recordCommissions/RecordCommissionsState';
import { Banner } from './Banner';
import { DialogBanner } from './DialogBanner';
import { DialogTitle } from './DialogTitle';
const toggleMode = (stateSetter) => () => stateSetter(prevState => (prevState === 'edit' ? 'view' : 'edit'));
const discardChanges = (modeStateSetter, setIsDiscardingChanges, isFormChanged) => () => {
    if (isFormChanged()) {
        setIsDiscardingChanges();
    }
    else {
        toggleMode(modeStateSetter)();
    }
};
const isViewMode = (mode) => mode === 'view';
const isEditMode = (mode) => mode === 'edit';
const onSubmit = () => undefined;
export const RecordCommissionsDialog = ({ onClose, artooId, paperworkId }) => {
    const [mode, setMode] = useState('view');
    const [isDiscardingChanges, setIsDiscardingChanges] = useState(false);
    const table = useAppSelector($RecordCommissionsSlice.selector.table);
    const isLoading = useAppSelector($RecordCommissionsSlice.selector.isLoading);
    const error = useAppSelector($RecordCommissionsSlice.selector.error);
    const initialValues = useAppSelector($RecordCommissionsSlice.selector.initialValues);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch($RecordCommissions.start({ artooId, paperworkId }));
        return () => {
            dispatch($RecordCommissions.stop());
        };
    }, [dispatch, artooId, paperworkId]);
    const withOpacity = (viewMode) => (viewMode === 'edit' ? { opacity: '0.5' } : { opacity: 'inherit' });
    return (_jsx(Dialog, { open: true, fullWidth: true, maxWidth: "md", children: _jsx(Formik, { enableReinitialize: true, initialValues: initialValues, onSubmit: onSubmit, children: ({ values, handleChange, resetForm }) => (_jsxs(Form, { children: [_jsx(DialogTitle, { title: "Provvigione", onClose: onClose }), _jsxs(DialogContent, { children: [error && _jsx(Banner, { message: error }), isLoading.table && (_jsx(Box, { style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 16,
                                }, children: _jsx(CircularProgress, {}) })), !isLoading.table && table === null && null, !isLoading.table && table !== null && (_jsxs(TableContainer, { component: Paper, style: { marginBottom: 16 }, children: [isLoading.editing && (_jsx("div", { style: {
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }, children: _jsx(CircularProgress, {}) })), _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), pipe(table.headers, readonlyArray.mapWithIndex((index, header) => (_jsx(TableCell, { align: "right", children: header }, index))))] }) }), _jsx(TableBody, { sx: {
                                                    '& .MuiTableRow-root:last-child': {
                                                        '& .MuiTableCell-root': {
                                                            '& .MuiTypography-root': {
                                                                'font-weight': 'bold',
                                                            },
                                                        },
                                                    },
                                                }, children: pipe(values, readonlyArray.mapWithIndex((indexRow, tableBody) => {
                                                    var _a, _b;
                                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: table.firstColumn[indexRow] }), _jsx(TableCell, { align: "right", children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { style: Object.assign({ display: 'inline', fontWeight: 'bold' }, withOpacity(mode)), children: (_a = table.payinColumn[indexRow]) === null || _a === void 0 ? void 0 : _a.registeredPayin }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { style: Object.assign({}, withOpacity(mode)), children: (_b = table.payinColumn[indexRow]) === null || _b === void 0 ? void 0 : _b.leftoverPayin }) })] }) }), pipe(tableBody, readonlyArray.mapWithIndex((indexCell, commission) => isViewMode(mode) || commission.isReadonly ? (_jsx(TableCell, { align: "right", style: Object.assign({ verticalAlign: 'bottom' }, withOpacity(mode)), children: _jsx(Typography, { children: commission.formattedValue }) }, indexCell)) : (_jsx(TableCell, { children: _jsx(TextField, { variant: "standard", InputProps: {
                                                                        endAdornment: (_jsx(InputAdornment, { position: "end", children: "\u20AC" })),
                                                                    }, inputProps: {
                                                                        style: {
                                                                            textAlign: 'right',
                                                                        },
                                                                        step: '.01',
                                                                    }, type: "number", onChange: handleChange, name: `${indexRow}.${indexCell}.value`, value: commission.value, disabled: isDiscardingChanges || isLoading.editing }) }, indexCell))))] }, indexRow));
                                                })) })] })] })), _jsx(Authorization, { permissions: [PERMISSION.EDIT_COMMISSIONS], children: pipe(isDiscardingChanges, boolean.match(constant(readonlyArray.isNonEmpty(initialValues) && (_jsxs(Grid, { container: true, item: true, justifyContent: "center", children: [isEditMode(mode) && (_jsx(Button, { size: "large", disabled: isLoading.editing, style: { marginRight: 8 }, onClick: discardChanges(setMode, toggle(setIsDiscardingChanges), CommissionsForm.isFormChanged(values, initialValues)), children: "Annulla" })), isEditMode(mode) && (_jsx(Button, { size: "large", disabled: isLoading.editing ||
                                                !CommissionsForm.isFormChanged(values, initialValues)(), onClick: () => dispatch($RecordCommissions.editCommissions({
                                                commissions: values,
                                                onSuccess: toggleMode(setMode),
                                            })), children: "Salva" })), isViewMode(mode) && (_jsx(Button, { disabled: isLoading.table, onClick: toggleMode(setMode), children: "Modifica" }))] }))), constant(_jsx(DialogBanner, { message: "Sei sicuro di voler annullare le modifiche?", onConfirm: () => {
                                        toggle(setIsDiscardingChanges)();
                                        toggleMode(setMode)();
                                        resetForm();
                                    }, onConfirmText: "Si", onCancel: toggle(setIsDiscardingChanges), onCancelText: "No" })))) })] })] })) }) }));
};
