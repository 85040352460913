import * as t from 'io-ts';
export const PaperworkCodec = t.type({
    id: t.string,
    artooId: t.union([t.string, t.number, t.null]),
    currentStatus: t.type({ code: t.string, label: t.string }),
    productType: t.string,
    assignedTo: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdBy: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdAt: t.string,
    data: t.intersection([
        t.type({
            nome: t.string,
            cognome: t.string,
            email: t.string,
            codice_fiscale: t.string,
            rubinetto: t.string,
            telefono_cellulare: t.string,
            tipo_di_prodotto_long: t.string,
        }),
        t.partial({
            tipo_di_prodotto: t.string,
            nome: t.string,
            cognome: t.string,
            data_erogazione_finanziamento: t.string,
            mutuo_istituto: t.string,
            prestito_finanziaria: t.string,
            cessione_del_quinto_finanziaria: t.string,
            anticipo_tfs_finanziaria: t.string,
            polizza_assicurativa_compagnia_assicurativa: t.string,
        }),
    ]),
}, 'Paperwork');
