import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
import { recordFetched } from './reducers/recordFetched';
const initialState = {
    loading: false,
    table: null,
    artooRecordAgents: null,
    artooRecordData: null,
    initialValues: { recordId: '' },
};
export const activeInvoicingSlice = createSlice({
    name: 'ActiveInvoicingSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        fetchRecord: (state, _) => state,
        RecordRequested: state => (Object.assign(Object.assign({}, state), { loading: true, table: null, artooRecordAgents: null, artooRecordData: null })),
        RecordFetched: (state, event) => recordFetched(state, event),
        RecordNotFetched: state => (Object.assign(Object.assign({}, state), { loading: false, table: null, artooRecordAgents: null, artooRecordData: null })),
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $ActiveInvoicing = activeInvoicingSlice.actions;
export const $activeInvoicingSlice = {
    selector: {
        table: (state) => state.activeInvoicing.table,
        isLoading: (state) => state.activeInvoicing.loading,
        initialValues: (state) => state.activeInvoicing.initialValues,
        artooRecordData: (state) => state.activeInvoicing.artooRecordData,
        artooRecordAgents: (state) => state.activeInvoicing.artooRecordAgents,
    },
};
export default activeInvoicingSlice.reducer;
