// eslint-disable-next-line import/no-duplicates
import { format as _format, parseISO as _parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { it } from 'date-fns/locale';
import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { string } from '../../lib/string';
const parseISO = (format) => (date) => pipe(option.tryCatch(() => pipe(date, _parseISO, parsedDate => _format(parsedDate, format, { locale: it }), string.capitalize)));
export const $date = {
    month: parseISO('LLLL yyyy'),
    dayMonthYear: parseISO('dd/MM/yyyy'),
};
