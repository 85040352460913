export const productTypologyMapper = (productTypology) => {
    switch (productTypology) {
        case 'cqs':
            return 'CQS';
        case 'mortgage':
            return 'Mutuo';
        case 'policy':
            return 'Polizza';
        case 'loan':
            return 'Prestito';
    }
};
